<template>
    <VPopper class="page-settings" offset-distance="6" placement="bottom-start" z-index="10000" v-bind="$attrs">
        <VButton class="button--secondary button--default button--center button--vcenter">
            <SettingsSvg></SettingsSvg>

            {{ t('settings') }}
        </VButton>

        <template #content="{ close }">
            <VGridContainer class="filter-popup">
                <VGridRow class="grid--space-between">
                    <VGridColumn class="grid--center">
                        <h5 class="filter-popup__header">
                            {{ t('settings') }}
                        </h5>
                    </VGridColumn>

                    <VGridColumn>
                        <VGridRow>
                            <VButton
                                class="button--rounded button--transparent button--vcenter button--margin-left"
                                @click="close"
                            >
                                <TimesSvg></TimesSvg>
                            </VButton>
                        </VGridRow>
                    </VGridColumn>
                </VGridRow>

                <VGridRow>
                    <VGridContainer>
                        <template v-for="(option, index) in localOptions" :key="index">
                            <VGridRow class="grid--padding-top-100" v-if="option.option.title">
                                {{ option.option.title }}:
                            </VGridRow>

                            <VGridRow
                                :class="{
                                    'grid--padding-top-25': option.option.title,
                                    'grid--padding-top-100': !option.option.title,
                                }"
                            >
                                <component
                                    v-bind="option.option.attrs"
                                    v-model="option.modelValue"
                                    v-on="{ [option.option.changeTrigger ?? 'update:modelValue']: () => onUpdate(option as LocalOption) }"
                                    :is="option.option.component"
                                ></component>
                            </VGridRow>
                        </template>
                    </VGridContainer>
                </VGridRow>
            </VGridContainer>
        </template>
    </VPopper>
</template>

<script setup lang="ts">
// Svg
import TimesSvg from '@/assets/times.svg';
import SettingsSvg from '@/assets/settings.svg';

// Components
import VPopper from 'vue3-popper';
import VButton from './VButton.vue';
import VGridRow from './VGridRow.vue';
import VGridColumn from './VGridColumn.vue';
import VGridContainer from './VGridContainer.vue';

// Other
import { useI18n } from 'vue-i18n';
import { PropType, ref, defineProps, Ref, watch, defineEmits, nextTick } from 'vue';
import IPageSettings, { IPageSettingsOption } from '@/core/Values/IPageSettings';

type LocalOption = { option: IPageSettingsOption; modelValue: Ref<unknown> };

const { t } = useI18n();
const props = defineProps({
    options: {
        type: Array as PropType<IPageSettingsOption[]>,
        default: () => [],
    },
    modelValue: {
        type: Object as PropType<Partial<IPageSettings>>,
        required: true,
    },
});
const emit = defineEmits(['update:modelValue']);

let localModelValue = ref(props.modelValue);
let localOptions = ref(mapOptions(props.options));

watch(
    () => props.options,
    () => (localOptions.value = mapOptions(props.options)),
);

function mapOptions(options: IPageSettingsOption[]): LocalOption[] {
    return options.map((option) => ({ option, modelValue: ref<unknown>(option.read(localModelValue.value)) }));
}

function onUpdate(option: LocalOption) {
    // nextTick is required to modelValue was correctly updated in some of cases.
    nextTick(() => {
        option.option.save(localModelValue.value, option.modelValue);
        emit('update:modelValue', localModelValue.value);
    });
}
</script>

<style lang="scss"></style>
