export default class DataTransferExtractor {
    public static extract(dataTransfer: DataTransfer) {
        const files: File[] = [];

        if (!DataTransferExtractor.containsFiles(dataTransfer)) {
            return files;
        }

        const items = dataTransfer.items;

        if (items?.length) {
            for (const item of items) {
                if (item.webkitGetAsEntry == null) {
                    continue;
                }

                const entry = item.webkitGetAsEntry();
                if (!entry?.isFile) {
                    continue;
                }

                const file = item.getAsFile();
                if (!file) {
                    continue;
                }

                files.push(file);
            }
        } else {
            for (let index = 0; index < dataTransfer.files.length; index++) {
                files[index] = dataTransfer.files[index];
            }
        }

        return files;
    }

    public static containsFiles(dataTransfer: DataTransfer): boolean {
        for (const type of dataTransfer.types) {
            if (type === 'Files') {
                return true;
            }
        }

        return false;
    }
}
