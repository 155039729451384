<template>
    <VPage :class="{ 'app-page--fullscreen': pageSettings.fullscreen }" v-if="goals">
        <VPageHeader show-third-block :title="t('my-goals')">
            <template v-slot:second>
                <div class="app-page-header__column app-page-header__column--tools">
                    <VPageSettings v-model="pageSettings" class="prevent-close" :options="pageOptions"></VPageSettings>

                    <VTaskFilter
                        v-model="filters"
                        class="prevent-close"
                        :filter-types="[
                            TaskFilterTypes.Author,
                            TaskFilterTypes.Status,
                            TaskFilterTypes.Assignee,
                            TaskFilterTypes.Approver,
                            TaskFilterTypes.Contributor,
                            TaskFilterTypes.AuthorOrAssignee,
                            TaskFilterTypes.Deadline,
                            TaskFilterTypes.CreationDate,
                        ]"
                    ></VTaskFilter>

                    <VButtonDropdown class="prevent-close" :options="regularActions" placement="left-start">
                        <DotsSvg class="rotate-90"></DotsSvg>
                    </VButtonDropdown>

                    <VSearchField v-model="searchString" class="prevent-close" debounce-mode></VSearchField>
                </div>
            </template>
        </VPageHeader>

        <VPageContent vertical>
            <VBoard center vertical v-if="pageSettings.viewMode === 'board'">
                <template v-for="(column, columnIndex) in columns" :key="columnIndex">
                    <VColumn
                        blurry
                        class="column--goals column--shrink column--padding-bottom"
                        :column="column"
                        no-scrolling
                        v-if="column.projectId === -1 || groupedGoals[column.boardId]?.length"
                    >
                        <ul class="column__list">
                            <template v-for="goal in groupedGoals[column.boardId]" :key="goal.id">
                                <li class="column__item">
                                    <RouterLink :to="{ name: 'goals.view', params: { goalId: goal.id } }">
                                        <VGoalCard :goal="goal"></VGoalCard>
                                    </RouterLink>
                                </li>
                            </template>
                        </ul>
                    </VColumn>
                </template>
            </VBoard>

            <VTable
                v-model:order="order"
                v-model:table="table"
                :resetOrder="resetOrder"
                v-else-if="pageSettings.viewMode === 'table'"
            >
                <template v-for="(column, columnIndex) in columns" :key="columnIndex">
                    <VTableDataSet :title="column.title" v-if="groupedGoals[column.boardId].length">
                        <template v-for="(goal, index) in groupedGoals[column.boardId]" :key="goal.id">
                            <RouterLink class="prevent-close" :to="{ name: 'goals.view', params: { goalId: goal.id } }">
                                <VTableRow
                                    :active="goal.id.toString() == route.query.task"
                                    :data="goal"
                                    :index="index"
                                    :table="table"
                                    @contextmenu="openContextMenu($event, goal)"
                                ></VTableRow>
                            </RouterLink>
                        </template>
                    </VTableDataSet>
                </template>
            </VTable>
        </VPageContent>
    </VPage>
</template>

<script lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';
import DownloadSvg from '@/assets/download.svg';

// Components
import VGoalCard from '../components/VGoalCard.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VColumn from '../components/VColumn.vue';
import VBoard from '../components/VBoard.vue';
import VTable from '../components/VTable.vue';
import VTableRow from '../components/VTableRow.vue';
import VTableDataSet from '../components/VTableDataSet.vue';
import VPageSettings from '../components/VPageSettings.vue';
import VButtonDropdown from '../components/VButtonDropdown.vue';

// Other
import sortBy from 'lodash.sortby';
import { setPageTitle } from '@/utils/document-utils';
import store from '@/store';
import IUser from '@/core/Models/IUser';
import TaskService, { ExportTaskRequest, QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import UserMapper from '@/core/UserMapper';
import TaskType from '@/core/Values/TaskType';
import IColumn from '@/core/Models/IColumn';
import { Raw, markRaw, ref, toRaw } from 'vue';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import MutationBus from '@/core/Mutations/MutationBus';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import Constants from '@/core/Constants';
import Status from '@/core/Values/Status';
import ProjectBoardType from '@/core/Values/ProjectBoardType';
import { defineComponent } from 'vue';
import orderBy from 'lodash.orderby';
import Settings from '@/core/Settings';
import Storages from '@/core/Storages';
import { useI18n } from 'vue-i18n';
import { tryTranslate } from '@/plugins/VueI18n';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import { useRoute, useRouter } from 'vue-router';
import { IOrder } from '@/core/Values/IOrder';
import useTaskTableViewer, { defaultColumns } from '@/mixins/TaskTableViewer';
import { usePageOptions } from '@/mixins/PageSettings';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import IPageSettings from '@/core/Values/IPageSettings';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { downloadFile } from '@/utils/utils';
import TaskExportSchema from '@/core/Values/TaskExportSchema';

function resetOrder() {
    return {
        orderBy: 'id',
        orderAscending: false,
    };
}

export default defineComponent({
    components: {
        DotsSvg,

        VGoalCard,
        VPage,
        VPageHeader,
        VPageContent,
        VTaskFilter,
        VSearchField,
        VColumn,
        VBoard,
        VTable,
        VTableRow,
        VTableDataSet,
        VPageSettings,
        VButtonDropdown,
    },

    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();

        const contextMenu = useTaskContextMenu();
        const table = useTaskTableViewer(Settings.UI.Table + '.user.goals', {
            header: true,
            columns: defaultColumns,
        });
        const defaultPageOptions = usePageOptions();
        const pageOptions = [defaultPageOptions.viewMode, defaultPageOptions.fullscreen];

        return {
            t,
            route,
            router,

            table,
            pageOptions,
            ...contextMenu,

            goals: ref([] as ITask[]),
            searchString: ref(''),
            mutatorContext: ref(null as Raw<IMutatorContext> | null),

            TaskFilterTypes: markRaw(TaskFilterTypes),
        };
    },

    computed: {
        order: Storages.Filters.computed<IOrder>(Settings.UI.Order + '.user.goals', resetOrder()),
        filters: Storages.Filters.computed(Settings.UI.Filters + '.user.goals', {
            value: [],
            formattedValue: {},
        }),
        pageSettings: Storages.Settings.computed<IPageSettings>(Settings.UI.PageSettings + '.user.goals', {
            viewMode: 'board',
            fullscreen: false,
        }),

        regularActions(): IDropdownOption[] {
            return [
                {
                    icon: markRaw(DownloadSvg),
                    title: this.t('export'),
                    action: () => {
                        downloadFile('/api/v1/tasks/export', {
                            ...this.goalsFilters,
                            withColumns: this.table.columns
                                .filter((column) => column.visible)
                                .map((column) => column.key),
                            schema: TaskExportSchema.List,
                            language: store.state.userSettings.locale,
                        } as ExportTaskRequest);
                    },
                },
            ];
        },

        filterKey(): string {
            return Settings.UI.Filters + '.' + (this.route.name?.toString() ?? 'shared');
        },

        currentUser(): IUser | null {
            return store.state.user;
        },

        columns(): IColumn[] {
            let columns: IColumn[] = [
                {
                    id: -1,
                    order: 0,
                    title: this.t('user-draft-column-title'),
                    boardId: Constants.DraftId,
                    projectId: -1,
                },
                {
                    id: -1,
                    order: 0,
                    title: this.t('user-approvements-column-title'),
                    boardId: Constants.ApprovementId,
                    projectId: -1,
                },
            ];

            if (!store.state.projects?.length || !store.state.boards?.length) {
                return columns;
            }

            columns = store.state.projects.reduce((carry, project): IColumn[] => {
                const boards = store.state.boards?.filter(
                    (board) => board.type === ProjectBoardType.Goals && board.projectId === project.id,
                );

                if (!boards?.length) {
                    return carry;
                }

                const projectTitle = tryTranslate(project.title);
                for (const board of boards) {
                    const boardTitle = tryTranslate(board.title);

                    const title = boardTitle + ', ' + projectTitle;
                    const orderStr = projectTitle + boardTitle;
                    const column: IColumn = {
                        id: -1,
                        order: 1,
                        title: title,
                        orderStr: orderStr,
                        boardId: board.id,
                        projectId: board.projectId,
                    };

                    carry.push(column);
                }

                return carry;
            }, columns);

            return sortBy(columns, (column) => column.order + (column.orderStr ?? ''));
        },

        groupedGoals(): Record<number, ITask[]> {
            return this.columns.reduce((carry: Record<number, ITask[]>, column) => {
                carry[column.boardId] = orderBy(
                    this.getTasks(column),
                    toRaw(this.order).orderBy,
                    toRaw(this.order).orderAscending ? 'asc' : 'desc',
                );

                return carry;
            }, {});
        },

        goalsFilters(): QueryTaskRequest {
            return {
                ...this.order,
                ...this.filters.formattedValue,
                whereType: [TaskType.Goal],
                whereColumnId: this.columns?.map((column) => column.id).filter((id) => id > 0),
                whereCollaborator: [this.currentUser?.id as string],
                includes: ['comments-count', 'attachments-count', 'approvements', 'collaborators'],
                search: this.searchString,
            };
        },
    },

    methods: {
        resetOrder,

        getTasks(column: IColumn): ITask[] {
            if (!this.goals?.length) {
                return [];
            }

            if (column.boardId === Constants.DraftId) {
                return this.goals.filter((goal) => goal.status === Status.Draft);
            }

            if (column.boardId === Constants.ApprovementId) {
                return this.goals.filter((goal) => goal.status === Status.Approvement);
            }

            const boardId = column.boardId > 0 ? column.boardId : null;

            return this.goals.filter((goal) => goal.boardId === boardId) ?? [];
        },

        async fetchData(): Promise<void> {
            this.goals = await TaskService.queryAsync(this.goalsFilters);

            // Attaching of users and preview to tasks.
            UserMapper.mapTasksAsync(this.goals);
            ObjectStorageMapper.mapTasksAsync(this.goals);

            this.mutatorContext = markRaw(
                new TaskMutatorContext(this.goals, {
                    mapUsers: true,
                    mapPreview: true,
                    // Fetches a task if the patch contains the changes that can affect of the filters.
                    fetchTask: async (patch: Partial<ITask>) => {
                        if (!TaskService.matchPatch(patch, this.currentUser?.id as string, this.goalsFilters)) {
                            return undefined;
                        }

                        const tasks = await TaskService.queryAsync({
                            ...this.goalsFilters,
                            whereId: [patch.id as number],
                            perPage: 1,
                        });

                        if (!tasks.length) {
                            return undefined;
                        }

                        return UserMapper.mapTaskAsync(tasks[0]);
                    },

                    // Excludes tasks if they are not matching the specified filters.
                    excludeTask: (task: ITask) =>
                        !TaskService.match(task, this.currentUser?.id as string, this.goalsFilters),
                    // Ignores tasks creation if they are not matching the specified filters.
                    ignoreTaskCreating: (task: ITask) =>
                        TaskService.match(task, this.currentUser?.id as string, this.goalsFilters),
                }),
            );
            MutationBus.activate(this.mutatorContext);
        },
    },

    watch: {
        goalsFilters: 'fetchData',
    },

    created(): void {
        this.fetchData();

        emitter.on(EventNames.ConnectionLoopReconnected, this.fetchData);
    },

    mounted(): void {
        setPageTitle(this.t('my-goals'));
    },

    beforeUnmount(): void {
        // Deactivate old context, to avoid memory leaks.
        MutationBus.deactivate(this.mutatorContext);
        emitter.off(EventNames.ConnectionLoopReconnected, this.fetchData);
    },
});
</script>
