<template>
    <div class="input-field-wrapper">
        <input
            class="input-field"
            :maxlength="maxlength"
            :value="value"
            v-bind="$attrs"
            @blur="focused = false"
            @focus="focused = true"
            @input="onInput"
        />

        <span
            class="input-field-wrapper__legend"
            :class="{
                'input-field-wrapper__legend--negative': value.length >= maxlengthNumber,
            }"
            v-if="maxlength"
            v-show="focused"
        >
            <span>{{ value.length }}</span
            >/<span>{{ maxlengthNumber }}</span>
        </span>
    </div>
</template>

<script lang="ts">
// Svg
// ...

// Components
// ...

// Other
import { debounce } from 'debounce';
import { defineComponent, markRaw } from 'vue';

type DebounceFunction = ((value: string) => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    inheritAttrs: false,

    props: {
        maxlength: { type: [Number, String], default: 0 },
        modelValue: { type: String, required: true, default: '' },
        debounceMode: { type: Boolean, default: false },
        debounceInterval: { type: Number, default: 500 },
        concurrencyMode: { type: Boolean, default: false },
    },

    data: () => ({
        value: '',
        focused: false,
        debounce: null as DebounceFunction | null,
    }),

    methods: {
        onInput(event: Event) {
            const value = (event.target as HTMLInputElement).value;
            // Fallback if the `maxlength` attribute does not work.
            if (this.maxlengthNumber && value.length > this.maxlengthNumber) {
                (event.target as HTMLInputElement).value = value.substring(0, this.maxlengthNumber);
                return;
            }

            this.value = value;

            const emit = (newValue: string) => {
                this.$emit('update:modelValue', newValue);
                this.debounce = null;
            };

            if (this.debounceMode && !this.debounce) {
                this.debounce = markRaw(debounce(emit, this.debounceInterval));
            }

            if (this.debounceMode && this.debounce) {
                this.debounce(value);
            } else {
                emit(value);
            }
        },
    },

    computed: {
        maxlengthNumber(): number {
            return parseInt(this.maxlength as string, 10) || 0;
        },
    },

    watch: {
        modelValue(value: string) {
            // If the user is in the input window, ignore all changes.
            if (this.concurrencyMode && this.focused) {
                return;
            }

            this.value = value;
        },
    },

    created() {
        this.value = this.modelValue;
    },
});
</script>

<style lang="scss">
.input-field {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: var(--background-color);
    --background-color: inherit;
    color: var(--text-black-primary);

    &--primary {
        @include input-primary(0.75rem);
    }

    &--bordered {
        border-color: var(--text-black-tertiary);
    }

    &--inline-50 {
        @include input-primary-width(0.5rem);
    }

    &--inline-75 {
        @include input-primary-width(0.75rem);
    }

    &--inline-100 {
        @include input-primary-width(1rem);
    }
}

.input-field-wrapper {
    position: relative;
    width: 100%;

    &__legend {
        position: absolute;
        padding: 0 0.25rem;
        right: 1.75rem;
        bottom: -0.5rem;
        background: var(--background-color);

        @include caption-tertiary();

        & span:first-child {
            color: var(--brand-green);
        }

        &--negative span {
            color: var(--color-negative) !important;
        }
    }
}
</style>
