import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-menu__trigger" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "user-menu__item user-menu__item--slot" }
const _hoisted_4 = { class: "user-menu__item button button__dropdown-option button__dropdown-option--link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsSvg = _resolveComponent("SettingsSvg")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ThemeSvg = _resolveComponent("ThemeSvg")!
  const _component_WinterOffSvg = _resolveComponent("WinterOffSvg")!
  const _component_WinterOnSvg = _resolveComponent("WinterOnSvg")!
  const _component_SignOutSvg = _resolveComponent("SignOutSvg")!
  const _component_VPopper = _resolveComponent("VPopper")!

  return (_openBlock(), _createBlock(_component_VPopper, _mergeProps({
    class: "user-menu prevent-close",
    "offset-distance": _ctx.offsetDistance,
    placement: _ctx.placement,
    "z-index": "10000"
  }, _ctx.$attrs), {
    content: _withCtx(({ close }) => [
      _createElementVNode("ul", {
        class: "button__dropdown-menu user-menu__item",
        onClick: close
      }, [
        _createElementVNode("li", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("li", { class: "user-menu__item user-menu__item--separator" }, null, -1)),
        _withDirectives(_createElementVNode("li", _hoisted_4, [
          _createVNode(_component_RouterLink, { to: { name: 'user.settings' } }, {
            default: _withCtx(() => [
              _createVNode(_component_SettingsSvg),
              _createTextVNode(" " + _toDisplayString(_ctx.t('settings')), 1)
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, false]
        ]),
        _createElementVNode("li", {
          class: "user-menu__item button button__dropdown-option",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleTheme && _ctx.toggleTheme(...args)))
        }, [
          _createVNode(_component_ThemeSvg, {
            class: _normalizeClass({ 'rotate-180': _ctx.theme === 'dark' })
          }, null, 8, ["class"]),
          (_ctx.theme === 'dark')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.t('light-theme')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.t('dark-theme')), 1)
              ], 64))
        ]),
        (_ctx.isNowWinter || _ctx.winterMode)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: "user-menu__item button button__dropdown-option",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleWinterMode && _ctx.toggleWinterMode(...args)))
            }, [
              (_ctx.winterMode)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_WinterOffSvg),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('disable-winter-mode')), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_WinterOnSvg),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('enable-winter-mode')), 1)
                  ], 64))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("li", {
          class: "user-menu__item button button__dropdown-option",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args)))
        }, [
          _createVNode(_component_SignOutSvg),
          _createTextVNode(" " + _toDisplayString(_ctx.t('sign-out')), 1)
        ])
      ], 8, _hoisted_2)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 16, ["offset-distance", "placement"]))
}