import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "simple-textarea" }
const _hoisted_2 = ["maxlength", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["simple-textarea__shadow", {
                'simple-textarea__shadow--visibly': _ctx.disabled,
            }])
    }, _toDisplayString(_ctx.displayValue), 3),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: _normalizeClass(["simple-textarea__placeholder", {
                    'required': _ctx.required,
                    'simple-textarea__placeholder--visible': !_ctx.value && !_ctx.focused,
                }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args)))
          }, _toDisplayString(_ctx.displayValue), 3),
          _createElementVNode("textarea", {
            ref: "textarea",
            autocomplete: "off",
            class: "simple-textarea__input",
            maxlength: _ctx.maxlength,
            value: _ctx.value,
            onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
            onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
            onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
          }, "            ", 40, _hoisted_2)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.maxlengthNumber && _ctx.value.length > _ctx.maxlengthNumber * 0.8)
      ? _withDirectives((_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(["simple-textarea__legend", {
                'simple-textarea__legend--negative': _ctx.value.length >= _ctx.maxlengthNumber,
            }])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.value.length), 1),
          _cache[5] || (_cache[5] = _createTextVNode("/")),
          _createElementVNode("span", null, _toDisplayString(_ctx.maxlengthNumber), 1)
        ], 2)), [
          [_vShow, _ctx.focused]
        ])
      : _createCommentVNode("", true)
  ]))
}