import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-error app-error--center" }
const _hoisted_2 = { class: "app-error__content app-error__content--illustration" }
const _hoisted_3 = { class: "app-error__actions" }
const _hoisted_4 = ["href"]

import Error500Svg from '../assets/error-500.svg';
import DontEnterSvg from '../assets/dont-enter.svg';

// Components
import VButton from './VButton.vue';
import VIllustration from './VIllustration.vue';

// Other
import { ErrorCodes } from '@/core/ErrorCodes';
import { markRaw, PropType } from 'vue';
import oidc from '@/vue-oidc-client';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VAppError',
  props: {
    error: {
        type: Object as PropType<Record<string, unknown>>,
        default: null,
    },
},
  setup(__props) {

// Svg
const { t } = useI18n();

const props = __props;

const errorCodes = markRaw(ErrorCodes);
const serviceDeskUri = markRaw(process.env.VUE_APP_SERVICE_DESK_URI);

const errorMessage =
    props.error?.message && typeof props.error?.message === 'string' ? props.error.message : 'unknown-error';

function reload() {
    location.reload();
    return false;
}

function logout() {
    oidc.signOut();
    return false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(errorMessage) === _unref(errorCodes).NotEnoughPermissions)
        ? (_openBlock(), _createBlock(VIllustration, {
            key: 0,
            tip: _unref(t)(_unref(errorMessage))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(DontEnterSvg))
            ]),
            _: 1
          }, 8, ["tip"]))
        : (_unref(errorMessage) === _unref(errorCodes).WebsocketConnectionError)
          ? (_openBlock(), _createBlock(VIllustration, {
              key: 1,
              class: "illustration--text-left",
              tip: _unref(t)(_unref(errorMessage))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(Error500Svg))
              ]),
              _: 1
            }, 8, ["tip"]))
          : (_openBlock(), _createBlock(VIllustration, {
              key: 2,
              tip: _unref(t)(_unref(errorMessage))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(Error500Svg))
              ]),
              _: 1
            }, 8, ["tip"])),
      _createElementVNode("div", _hoisted_3, [
        (_unref(errorMessage) === _unref(errorCodes).NotEnoughPermissions)
          ? (_openBlock(), _createBlock(VButton, {
              key: 0,
              class: "button--rectangle button--center button--blurry app-error__action",
              onClick: logout
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('change-account')), 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(VButton, {
              key: 1,
              class: "button--rectangle button--center button--blurry app-error__action",
              onClick: reload
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('reload')), 1)
              ]),
              _: 1
            })),
        _createElementVNode("a", {
          class: "button button--rectangle button--center button--negative app-error__action",
          href: _unref(serviceDeskUri),
          target: "_blank"
        }, _toDisplayString(_unref(t)('goto-service-desk')), 9, _hoisted_4)
      ])
    ])
  ]))
}
}

})