<template>
    <div class="column">
        <div class="column__row column__row--header">
            <template v-if="editable">
                <VInput
                    class="input-field--primary input-field--inline-50 column__title"
                    concurrency-mode
                    debounce-mode
                    maxlength="64"
                    :model-value="column.title"
                    :placeholder="t('enter-title')"
                    @update:model-value="setTitle"
                ></VInput>
            </template>

            <template v-else>
                <h4
                    class="column__title column__title--text"
                    :class="{
                        'column__title--blurry': blurry,
                    }"
                >
                    {{ column.title ? column.title : t('untitled') }}
                </h4>
            </template>

            <button
                class="button button--rounded button--transparent column__button column__button--action inline-space-100-left"
                @click="$emit('create-empty-task')"
                v-if="createButton"
            >
                <PlusSvg></PlusSvg>
            </button>
        </div>

        <div class="column__row column__row--content" v-if="renderScrollbar">
            <template v-if="noScrolling">
                <slot></slot>
            </template>

            <template v-else>
                <div class="column__scroll">
                    <slot></slot>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
import PlusSvg from '@/assets/plus.svg';

// Components
import VInput from './VInput.vue';

// Other
import IColumn from '@/core/Models/IColumn';
import { debounce } from 'debounce';
import { defineProps, nextTick, PropType, ref, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['set-title', 'create-empty-task']);
const props = defineProps({
    column: { type: Object as PropType<IColumn>, required: true },
    blurry: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    noScrolling: { type: Boolean, default: false },
    createButton: { type: Boolean, default: false },
    wheelPropagation: { type: Boolean, default: false },
});

const renderScrollbar = ref(false);
const setTitle = debounce((newValue: string) => {
    emit('set-title', newValue);
}, 500);

if (props.noScrolling) {
    renderScrollbar.value = true;
} else {
    // Do you really wanna know why im doing that?
    nextTick(() => (renderScrollbar.value = true));
}
</script>

<style lang="scss">
.column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    max-width: 15.75rem;
    height: 100%; // DON'T TOUCH
    min-height: 0; // DON'T TOUCH
    flex-shrink: 0;

    @media (min-width: #{$breakpoint-tablet + 1}) {
        max-width: 18.75rem;
    }

    &--add {
        padding: 0 1rem;
    }

    &--shrink {
        height: auto;
        min-height: auto;
    }

    &--goals {
        width: 100%;
        max-width: 42.5rem;

        @media (min-width: #{$breakpoint-tablet + 1}) {
            max-width: 52.5rem;
            width: 100%;
        }
    }

    &--padding-bottom {
        padding-bottom: 2rem;
    }

    &__row {
        display: flex;

        &--header {
            width: 100%;
            flex-shrink: 1;
        }

        &--content {
            height: 100%; // DON'T TOUCH
            min-height: 0; // DON'T TOUCH
        }
    }

    &__title {
        @include h4();

        &--text {
            margin: 0;
            flex-grow: 1;
            line-height: 2.5rem;
            @include overflow-ellipsis();
        }

        &--blurry {
            color: var(--text-black-tertiary);
        }
    }

    &__button {
        &--action {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0.5rem 0.375rem 0.5rem 0.75rem;
            padding: 0;

            color: var(--text-black-primary);
        }
    }

    // DON'T FUC***G TOUCH THIS MF (no offense it's for future me)
    // F*ck you, I'll decide for myself what to do and what not to do, got it?
    // Wanna some sugar, one spoonful or two?
    &__list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        min-height: 100%;
        margin: 0;
        padding-bottom: 0.75rem;

        &--no-padding {
            padding: 0;
        }
    }

    &__item {
        margin-right: 0.5rem;
    }

    &__scroll {
        width: 100%;
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: auto;
    }
}
</style>
