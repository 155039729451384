import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-table__index"
}

import { PropType, computed } from 'vue';
import ITable, { ITableColumn } from '@/core/Values/ITable';
import ITask from '@/core/Models/ITask';


export default /*@__PURE__*/_defineComponent({
  __name: 'VTableRow',
  props: {
    table: {
        type: Object as PropType<ITable>,
        required: true,
    },
    data: {
        type: Object as PropType<unknown>,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
    offset: {
        type: Number,
        default: 0,
    },
    active: {
        type: Boolean,
        default: false,
    },
},
  setup(__props) {

// Svg
// ...

// Components

// Other
const props = __props;

const firstIndex = computed(() => props.table.columns.findIndex((column) => column.visible));

function getColumnStyles(table: ITable<ITask>, cell: ITableColumn<ITask>) {
    const styles: Record<string, string> = {
        width: (cell.width ? cell.width : 100 / table.columns.length) + '%',
    };

    return styles;
}

function getContentStyles(table: ITable<ITask>, cell: ITableColumn<ITask>, index: number) {
    const styles: Record<string, string> = {};

    if (props.offset && index === firstIndex.value) {
        styles.paddingLeft = props.offset + 'rem';
    }

    return styles;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["v-table__row v-table__row--hover", {
            'v-table__row--active': props.active,
        }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.table.columns, (column, cellIndex) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: column.title
      }, [
        (column.visible)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: _normalizeClass(["v-table__column v-table__column--border v-table__column--borderless", {
                    'v-table__column--indexed': cellIndex === firstIndex.value,
                }]),
              style: _normalizeStyle(getColumnStyles(props.table, column))
            }, [
              (firstIndex.value === cellIndex)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.index + 1), 1))
                : _createCommentVNode("", true),
              (column.text)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: "v-table__content v-table__content--text",
                    style: _normalizeStyle(getContentStyles(props.table, column, cellIndex))
                  }, _toDisplayString(column.text(__props.data, __props.index)), 5))
                : (column.render)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(column.render(__props.data, __props.index)), {
                      key: 2,
                      style: _normalizeStyle(getContentStyles(props.table, column, cellIndex))
                    }, null, 8, ["style"]))
                  : (column.component)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(column.component), {
                        key: 3,
                        data: __props.data,
                        index: __props.index,
                        style: _normalizeStyle(getContentStyles(props.table, column, cellIndex))
                      }, null, 8, ["data", "index", "style"]))
                    : _createCommentVNode("", true)
            ], 6))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 2))
}
}

})