<template>
    <template v-if="notification.payload?.task && notification.payload?.stories?.length">
        <VFeedStory
            force-collapse-first
            :show-count="1000"
            :stories="notification.payload?.stories"
            :task="notification.payload?.task"
        ></VFeedStory>
    </template>

    <template v-else-if="notification.payload.content.length">
        <template v-for="(content, index) in notification.payload.content" :key="index">
            <span class="notification-content notification-content--as-story">
                {{ te(content.text) ? t(content.text, content.parameters ?? []) : content.text }}
            </span>
            <br />
        </template>
    </template>

    <template v-else>
        <span class="notification-content notification-content--as-story">
            {{ t('deleted') }}
        </span>
    </template>
</template>

<script setup lang="ts">
// Svg

// Components
import VFeedStory from './VFeedStory.vue';

// Other
import INotification from '@/core/Models/INotification';
import { PropType, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, te } = useI18n();
defineProps({
    notification: { type: Object as PropType<INotification>, required: true },
});
</script>

<style lang="scss">
.notification-content {
    &--as-story {
        margin: 0 0 1rem 2.5rem;

        color: var(--text-black-primary);
        @include caption-tertiary(false);
    }
}
</style>
