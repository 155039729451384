import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-page-header" }
const _hoisted_2 = { class: "app-page-header__column app-page-header__column--title app-page-header__column--priority" }

import VUserMenu from './VUserMenu.vue';
import VUserCard from './VUserCard.vue';

// Other
import store from '@/store';



export default /*@__PURE__*/_defineComponent({
  __name: 'VPageHeader',
  props: {
    title: { type: String, default: '' },
    showThirdBlock: { type: Boolean, default: false },
},
  setup(__props) {

// Svg
// ...

// Components


const currentUser = store.state.user;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "title", {}, () => [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.title), 1)
    ]),
    _renderSlot(_ctx.$slots, "second"),
    (__props.showThirdBlock && !_ctx.$device.isMobile)
      ? _renderSlot(_ctx.$slots, "third", { key: 0 }, () => [
          _createVNode(VUserMenu, null, {
            default: _withCtx(() => [
              _createVNode(VUserCard, {
                "picture-only": _ctx.$device.isMobile,
                user: _unref(currentUser)
              }, null, 8, ["picture-only", "user"])
            ]),
            _: 1
          })
        ])
      : _createCommentVNode("", true)
  ]))
}
}

})