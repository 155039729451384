import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "scroll",
    class: _normalizeClass(["app-page-content app-page-content--scroll", {
            'app-page-content--align-center': _ctx.alignCenter,
            'app-page-content--justify-center': _ctx.justifyCenter,
            'app-page-content--vertical': _ctx.vertical,
            'app-page-content--horizontal': _ctx.horizontal,
        }]),
    onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 34))
}