import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "story-record__header"
}
const _hoisted_2 = {
  key: 0,
  class: "story-record__header-content"
}
const _hoisted_3 = { class: "story-record__header-timestamp" }
const _hoisted_4 = ["edited-text"]
const _hoisted_5 = {
  key: 2,
  class: "story-record__content story-record__content--attachment"
}
const _hoisted_6 = {
  key: 3,
  class: "story-record__layer story-record__layer--left"
}
const _hoisted_7 = {
  key: 4,
  class: "story-record__layer story-record__layer--right story-record__layer--actions story-record__layer--show-on-hover"
}

import DotsSvg from '@/assets/dots.svg';

// Components
import VUserCard from './VUserCard.vue';
import VAttachment from './VAttachment.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VFeedStoryRecordContentRenderer from './VFeedStoryRecordContentRenderer.vue';

// Other
import IFeedStoryRecord from '@/core/Values/IFeedStoryRecord';
import { formatDateOnly, formatWithTime } from '@/utils/date-utils';
import { PropType, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VFeedStoryRecord',
  props: {
    record: { type: Object as PropType<IFeedStoryRecord>, required: true },
    selectable: { type: Boolean, default: false },
},
  setup(__props) {

// Svg
const { t } = useI18n();
const props = __props;

let active = ref(false);

const content = computed(() => props.record.content);
const timestamp = computed(() => (!props.record?.timestamp ? '' : formatDateOnly(props.record.timestamp)));
const timestampWithTime = computed(() => (!props.record?.timestamp ? '' : formatWithTime(props.record.timestamp)));

function toggleActive() {
    active.value = !active.value;
}

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["story-record", {
            'story-record--hover': __props.selectable,
            'story-record--active': _unref(active),
            'story-record--offset': __props.record.offset,
            'story-record--padding': __props.record.padding,
        }])
  }, [
    ((__props.record.header || __props.record.actor) && !__props.record.collapsed)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createElementVNode("span", {
            class: _normalizeClass(["story-record__header-actor", {
                    'story-record__header-actor--weak': __props.record.actor?.weakColor,
                    'story-record__header-actor--transparent': __props.record.actor?.hideActor,
                }])
          }, _toDisplayString(__props.record.actor?.displayName) + " ", 3),
          (__props.record.header?.content)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createVNode(VFeedStoryRecordContentRenderer, {
                  content: __props.record.header.content
                }, null, 8, ["content"]),
                _cache[0] || (_cache[0] = _createTextVNode("  "))
              ]))
            : _createCommentVNode("", true),
          _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createTextVNode(_toDisplayString(timestamp.value), 1)
          ])), [
            [_directive_tooltip, timestampWithTime.value]
          ])
        ]))
      : _createCommentVNode("", true),
    (content.value)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(["story-record__content", {
                'story-record__content--edited': __props.record.contentEdited,
            }]),
          "edited-text": '(' + _unref(t)('edited') + ')'
        }, _toDisplayString(content.value), 11, _hoisted_4))
      : _createCommentVNode("", true),
    (__props.record.attachment)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(VAttachment, {
            attachment: __props.record.attachment
          }, null, 8, ["attachment"])
        ]))
      : _createCommentVNode("", true),
    (__props.record.actor?.withPicture && !__props.record.collapsed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(VUserCard, {
            "picture-only": "",
            user: __props.record.actor
          }, null, 8, ["user"])
        ]))
      : _createCommentVNode("", true),
    (__props.record.actions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(VButtonDropdown, {
            options: __props.record.actions,
            placement: "left",
            "onClose:popper": toggleActive,
            "onOpen:popper": toggleActive
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(DotsSvg))
            ]),
            _: 1
          }, 8, ["options"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})