<template>
    <div class="app-page-header">
        <slot name="title">
            <h1 class="app-page-header__column app-page-header__column--title app-page-header__column--priority">
                {{ title }}
            </h1>
        </slot>

        <slot name="second"> </slot>

        <template v-if="showThirdBlock && !$device.isMobile">
            <slot name="third">
                <VUserMenu>
                    <VUserCard :picture-only="$device.isMobile" :user="currentUser"></VUserCard>
                </VUserMenu>
            </slot>
        </template>
    </div>
</template>

<script setup lang="ts">
// Svg
// ...

// Components
import VUserMenu from './VUserMenu.vue';
import VUserCard from './VUserCard.vue';

// Other
import store from '@/store';
import { defineProps } from 'vue';

defineProps({
    title: { type: String, default: '' },
    showThirdBlock: { type: Boolean, default: false },
});

const currentUser = store.state.user;
</script>

<style lang="scss">
.app-page-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0 0.75rem;
    padding: 0.5rem 0 0.5rem;

    @media (min-width: #{$breakpoint-tablet + 1}) {
        gap: 2rem;
        margin: 0 2rem;
        padding: 1.5rem 0 1.5rem;
        flex-direction: row;
    }

    &__column {
        height: 2.625rem;

        &--title {
            @include h1();
            margin: 0;
            padding: 0;

            text-wrap: nowrap;
            line-height: 2.625rem;
        }

        &--priority {
            flex-grow: 1;
            flex-shrink: 1;
        }

        &--tools {
            display: flex;
            gap: 0.75rem;
            align-items: center;
        }
    }
}
</style>
