<template>
    <div
        class="task-card"
        :class="{
            'task-card--expired': isExpired,
            'task-card--finished': isFinished,
            'task-card--selected': isSelected,
        }"
        :task-id="task.id"
        @contextmenu="openContextMenu($event, task)"
    >
        <template v-if="task.previewDownloadUri">
            <img alt="" class="task-card__preview" :src="task.previewDownloadUri" />
        </template>

        <div class="task-card__content">
            <p
                class="task-card__row task-card__title"
                :class="{
                    'task-card__title--blurry': !task.title,
                }"
            >
                {{ task.title ? task.title : t('untitled') }}
            </p>

            <div class="task-card__row task-card__row--semi-separate">
                <VTaskStatus :task="task"></VTaskStatus>
            </div>

            <div class="task-card__row task-card__row--horizontal task-card__row--gap task-card__row--separate">
                <div class="task-card__hint" v-if="contributors.length > 0 && contributors[0].user">
                    <VUserCard picture-only tiny :user="contributors[0].user"></VUserCard>

                    <span class="task-card__counter" v-if="contributors.length > 1">
                        +{{ contributors.length - 1 }}
                    </span>
                </div>

                <span class="task-card__hint task-card__hint--space-right">
                    {{ formattedDeadline ? formattedDeadline : t('no-deadline') }}
                </span>

                <div class="task-card__hint" v-if="task.description">
                    <TextSvg></TextSvg>
                </div>

                <div class="task-card__hint" v-if="numberOfComments > 0">
                    <EmailSvg></EmailSvg>

                    {{ numberOfComments }}
                </div>

                <div class="task-card__hint" v-if="numberOfAttachments > 0">
                    <AttachmentSvg></AttachmentSvg>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// Svg
import TextSvg from '@/assets/text.svg';
import EmailSvg from '@/assets/email.svg';
import AttachmentSvg from '@/assets/attachment.svg';

// Components
import VTaskStatus from './VTaskStatus.vue';
import VUserCard from './VUserCard.vue';

// Other
import ITask from '@/core/Models/ITask';
import StoryType from '@/core/Values/StoryType';
import { formatDateOnly } from '@/utils/date-utils';
import { DateTime } from 'luxon';
import Status from '@/core/Values/Status';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import ICollaborator from '@/core/Models/ICollaborator';
import IUser from '@/core/Models/IUser';
import store from '@/store';
import { defineComponent } from 'vue';
import { PropType } from 'vue';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default defineComponent({
    components: {
        TextSvg,
        EmailSvg,
        AttachmentSvg,

        VTaskStatus,
        VUserCard,
    },

    props: {
        task: { type: Object as PropType<ITask>, required: true },
    },

    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const contextMenu = useTaskContextMenu();
        return {
            t,
            route,

            ...contextMenu,
        };
    },

    data: () => ({
        Status,
    }),

    computed: {
        taskOrGoal(): ITask {
            return this.task;
        },

        numberOfComments(): number {
            if (typeof this.task.commentsCount === 'number') {
                return this.task.commentsCount;
            }

            return this.task.stories?.filter((story) => story.type === StoryType.Comment)?.length ?? 0;
        },

        numberOfAttachments(): number {
            if (typeof this.task.attachmentsCount === 'number') {
                return this.task.attachmentsCount;
            }

            return this.task.stories?.filter((story) => story.type === StoryType.Attachment)?.length ?? 0;
        },

        formattedDeadline(): string {
            return this.task?.deadline ? formatDateOnly(this.task.deadline) : '';
        },

        isExpired(): boolean {
            if (!this.task.deadline || this.task.status !== Status.InProgress) {
                return false;
            }

            return DateTime.fromISO(this.task.deadline, { zone: 'UTC' }) < DateTime.now();
        },

        isFinished(): boolean {
            return this.task.status === Status.Finished;
        },

        isCompleted(): boolean {
            return this.task.status === Status.Completed;
        },

        isSelected(): boolean {
            return this.task.id === parseInt(this.route.query.task as string, 10);
        },

        contributors(): ICollaborator[] {
            return (
                this.task.collaborators?.filter((collaborator) => collaborator.role === CollaboratorRole.Assignee) ?? []
            );
        },

        currentUser(): IUser | null {
            return store.state.user;
        },
    },
});
</script>

<style lang="scss">
.task-card {
    width: 1px;
    min-width: 100%;
    max-width: 15.75rem;

    @media (min-width: #{$breakpoint-tablet + 1}) {
        max-width: 18.75rem;
    }

    border-radius: 1rem;
    border: 0.0625rem solid var(--background-tertiary);
    background: var(--background-color);
    --background-color: var(--background-primary);

    &--expired {
        background: var(--background-color);
        --background-color: var(--background-red);
    }

    &--completed {
        background: var(--background-color);
        --background-color: var(--background-green);
    }

    &--finished {
        background: var(--background-color);
        --background-color: var(--background-green);
    }

    &--selected {
        border-color: var(--color-blue);
        background: var(--background-color);
        --background-color: var(--background-blue);
    }

    &__row {
        &--gap {
            gap: 0.5rem;
        }

        &--separate {
            margin-top: 0.75rem;
        }

        &--semi-separate {
            margin-top: 0.375rem;
        }

        &--horizontal {
            display: flex;
        }
    }

    &__hint {
        display: flex;
        height: 1.5rem;
        line-height: 1.5rem;

        text-wrap: nowrap;
        color: var(--text-black-secondary);

        &--space-right {
            margin-right: auto;
        }
    }

    &__counter {
        margin-left: 0.25rem;
    }

    &__title {
        word-wrap: break-word;
        margin: 0;
        @include h7();

        color: var(--text-black-primary);

        &--blurry {
            color: var(--text-black-tertiary);
        }
    }

    &__preview {
        width: 100%;
        height: auto;
        max-height: 10rem;
        margin-bottom: -0.5rem;
        object-fit: cover;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    &__content {
        padding: 0.75rem 1rem 1rem 1rem;
    }
}
</style>
