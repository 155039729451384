<template>
    <VPage>
        <VPageHeader show-third-block :title="title">
            <template v-slot:second>
                <div class="app-page-header__column app-page-header__column--tools">
                    <VSearchField
                        v-model="searchString"
                        class="prevent-close"
                        debounce-mode
                        @update:model-value="applySearchFilter"
                    ></VSearchField>
                </div>
            </template>
        </VPageHeader>

        <VPageContent vertical> </VPageContent>
    </VPage>
</template>

<script lang="ts">
// Components
import VSearchField from '../components/VSearchField.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';

// Other
import { setPageTitle } from '@/utils/document-utils';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: {
        VSearchField,
        VPage,
        VPageHeader,
        VPageContent,
    },

    setup() {
        const { t } = useI18n();
        return {
            t,

            title: ref(''),
            searchString: ref(''),
        };
    },

    computed: {},

    methods: {
        applySearchFilter() {
            return;
        },
    },

    created(): void {
        this.title = this.t('settings');
    },

    mounted(): void {
        setPageTitle(this.title);
    },
});
</script>
