<template>
    <div class="v-table" @scroll="onScroll" v-if="table">
        <div class="v-table__container">
            <div class="v-table__wrapper">
                <div
                    class="v-table__header prevent-close"
                    :class="{ 'v-table__header--with-shadow': isScrolled }"
                    v-if="table.header"
                >
                    <VDraggable
                        v-model="table.columns"
                        class="v-table__row v-table__row--header"
                        draggable=".v-table__column--sortable"
                        :force-fallback="true"
                        group="tasks"
                        item-key="id"
                        :scroll-sensitivity="200"
                        tag="div"
                        @update="emit('update:table', table)"
                    >
                        <template v-slot:item="{ element, index }">
                            <div
                                class="v-table__column v-table__column--border v-table__column--priority v-table__column--draggable v-table__column--header"
                                :class="{
                                    'v-table__column--sortable': element.draggable,
                                    'v-table__column--indexed': index === firstIndex,
                                    'v-table__column--border-left-borderless': index === firstIndex,
                                    'v-table__column--border-right-borderless': index === lastIndex,
                                }"
                                :style="{ width: element.width + '%' }"
                                @click="
                                    setOrderBy(
                                        element,
                                        element.orderKey === order.orderBy ? !order.orderAscending : true,
                                    )
                                "
                                @contextmenu.prevent="openContextMenu($event, element)"
                                v-if="element.visible"
                            >
                                <template v-if="firstIndex === index">
                                    <span class="v-table__index"> # </span>
                                </template>

                                <span class="v-table__content--text">
                                    {{ element.title }}
                                </span>

                                <span>
                                    <template v-if="element.orderKey === order.orderBy && order.orderAscending">
                                        <OrderAscSvg class="svg svg--125 svg--margin-25x svg--middle"></OrderAscSvg>
                                    </template>

                                    <template v-else-if="element.orderKey === order.orderBy && !order.orderAscending">
                                        <OrderDescSvg class="svg svg--125 svg--margin-25x svg--middle"></OrderDescSvg>
                                    </template>
                                </span>
                            </div>
                        </template>

                        <template #footer>
                            <VSplitpanes
                                class="v-table__row v-table__row--header v-table__row--header-shadow v-table__row--invisible"
                                @resize="onResize"
                            >
                                <template v-for="(column, index) in table.columns" :key="index">
                                    <VPane
                                        class="v-table__column v-table__column--border v-table__column--borderless"
                                        :class="{
                                            'v-table__column--index': index === firstIndex,
                                        }"
                                        :min-size="column.minWidth ?? 10"
                                        :size="column.width"
                                        :style="{ width: column.width + '%' }"
                                        v-if="column.visible"
                                    >
                                    </VPane>
                                </template>
                            </VSplitpanes>
                        </template>
                    </VDraggable>
                </div>

                <div class="v-table__body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
// Svg
import TableSvg from '@/assets/table.svg';
import TimesSvg from '@/assets/times.svg';
import ArrowsSvg from '@/assets/arrows.svg';
import EyeOffSvg from '@/assets/eye-off.svg';
import ArrowLeftSvg from '@/assets/arrow-left.svg';
import OrderAscSvg from '@/assets/order-asc.svg';
import OrderDescSvg from '@/assets/order-desc.svg';

// Components
import VDraggable from 'vuedraggable';
import { Splitpanes as VSplitpanes, Pane as VPane, RequestUpdateType } from 'splitpanes';

// Other
import { PropType, ref, defineProps, toRef, defineEmits, h, computed } from 'vue';
import ITable, { ITableColumn } from '@/core/Values/ITable';
import debounce from 'debounce';
import { DebounceInstance } from '@/core/Types';
import ContextMenu, { MenuItem } from '@imengyu/vue3-context-menu';
import { useI18n } from 'vue-i18n';
import { IOrder } from '@/core/Values/IOrder';
import VCheckbox from './VCheckbox.vue';

const { t } = useI18n();
const emit = defineEmits(['update:table', 'update:order']);
const props = defineProps({
    table: {
        type: Object as PropType<ITable>,
        default: () => ({}),
    },
    order: {
        type: Object as PropType<IOrder>,
        required: true,
    },
    resetOrder: {
        type: Function as PropType<() => IOrder>,
        default: null,
    },
});

let table = toRef(props.table);
let order = toRef(props.order);
let isScrolled = ref(false);
let debounceInstance: DebounceInstance<ITable> | null = null;

if (table.value.columns.length && !table.value.columns[0].width) {
    let total = 100;
    const columns = table.value.columns.filter((column) => column.visible).reverse();
    columns.forEach((column, index) => {
        if (index === columns.length - 1) {
            column.width = total;
        } else {
            total = total - (column.minWidth ?? 10);
            column.width = column.minWidth ?? 10;
        }
    });
    emit('update:table', table.value);
}

const lastIndex = computed(() => table.value.columns.findLastIndex((column) => column.visible));
const firstIndex = computed(() => table.value.columns.findIndex((column) => column.visible));

function openContextMenu(event: MouseEvent, column: ITableColumn) {
    const items = getContextMenuItems(table.value, column);

    if (!items?.filter((item) => !item.hidden).length) {
        return;
    }

    ContextMenu.showContextMenu({
        x: event.x,
        y: event.y,
        zIndex: 100000,
        customClass: 'prevent-close',

        items,
    });
}

function getContextMenuItems(table: ITable, column: ITableColumn): MenuItem[] {
    const canMoveLeft = getLeftColumnIndex(table, column) !== -1;
    const canMoveRight = getRightColumnIndex(table, column) !== -1;

    const items: MenuItem[] = [
        {
            icon: () => h(OrderAscSvg, { class: 'svg svg--150' }),
            label: t('order-ascending'),
            onClick: () => setOrderBy(column, true),
            hidden: !column.sortable,
        },
        {
            icon: () => h(OrderDescSvg, { class: 'svg svg--150' }),
            label: t('order-descending'),
            onClick: () => setOrderBy(column, false),
            hidden: !column.sortable,
        },

        {
            divided: 'self',
            hidden: !column.sortable,
        },

        {
            icon: () => h(ArrowsSvg, { class: 'svg svg--150' }),
            label: t('sort'),
            children: [
                {
                    icon: () => h(TimesSvg, { class: 'svg svg--150' }),
                    label: t('order-reset'),
                    onClick: () => resetOrderBy(),
                },
                {
                    icon: () => h(OrderAscSvg, { class: 'svg svg--150' }),
                    label: t('order-ascending'),
                    onClick: () => setOrderBy(column, true),
                },
                {
                    icon: () => h(OrderDescSvg, { class: 'svg svg--150' }),
                    label: t('order-descending'),
                    onClick: () => setOrderBy(column, false),
                },
            ],
            hidden: !column.sortable,
        },

        {
            icon: () => h(ArrowsSvg, { class: 'svg svg--150 rotate-90' }),
            label: t('move'),
            children: [
                {
                    icon: () => h(ArrowLeftSvg, { class: 'svg svg--150' }),
                    label: t('move-left'),
                    hidden: !canMoveLeft,
                    onClick: () => moveColumnLeft(table, column),
                },
                {
                    icon: () => h(ArrowLeftSvg, { class: 'svg svg--150 rotate-180' }),
                    label: t('move-right'),
                    hidden: !canMoveRight,
                    onClick: () => moveColumnRight(table, column),
                },
            ],
            hidden: !column.draggable || (!canMoveLeft && !canMoveRight),
        },

        {
            divided: 'self',
            hidden: !column.draggable || (!canMoveLeft && !canMoveRight),
        },

        {
            icon: () => h(EyeOffSvg, { class: 'svg svg--150' }),
            label: t('hide-column'),
            hidden: column.required,
            onClick: () => toggleColumnVisibility(table, column),
        },

        {
            icon: () => h(TableSvg, { class: 'svg svg--150' }),
            label: t('columns'),
            children: table.columns
                .filter((column) => !column.required)
                .map((column) => ({
                    label: column.title,
                    onClick: () => toggleColumnVisibility(table, column),
                    icon: () => h(VCheckbox, { modelValue: column.visible }),
                    clickClose: false,
                })),
        },
    ];
    return items;
}

function setOrderBy(column: ITableColumn, ascending: boolean) {
    if (!column.sortable || !column.orderKey) {
        return;
    }

    order.value.orderBy = column.orderKey;
    order.value.orderAscending = ascending;

    emit('update:order', order.value);
}

function resetOrderBy() {
    order.value = props.resetOrder() ?? undefined;
    emit('update:order', order.value);
}

function toggleColumnVisibility(table: ITable, column: ITableColumn) {
    column.visible = !column.visible;

    const columns = table.columns.filter((column) => column.visible);
    const totalWidth = columns.reduce((total, column) => total + (column.width ?? 0), 0);

    if (totalWidth !== 0) {
        const firstColumn = columns[0];
        const isColumnAdded = column.visible;

        if (!firstColumn.width) {
            return;
        }

        if (isColumnAdded) {
            column.width = column.minWidth ?? 10;
            firstColumn.width = firstColumn.width - column.width;
        } else {
            firstColumn.width = firstColumn.width + (column.width ?? 0);
        }
    }

    emit('update:table', table);
}

function moveColumnLeft(table: ITable, column: ITableColumn) {
    if (!column.draggable) {
        return;
    }

    const columnIndex = table.columns.indexOf(column);
    const leftColumnIndex = getLeftColumnIndex(table, column);

    if (leftColumnIndex === -1) {
        return;
    }

    table.columns[columnIndex] = table.columns[leftColumnIndex];
    table.columns[leftColumnIndex] = column;

    emit('update:table', table);
}

function moveColumnRight(table: ITable, column: ITableColumn) {
    if (!column.draggable) {
        return;
    }

    const columnIndex = table.columns.indexOf(column);
    const rightColumnIndex = getRightColumnIndex(table, column);

    if (rightColumnIndex === -1) {
        return;
    }

    table.columns[columnIndex] = table.columns[rightColumnIndex];
    table.columns[rightColumnIndex] = column;

    emit('update:table', table);
}

function getLeftColumnIndex(table: ITable, column: ITableColumn) {
    const columnIndex = table.columns.indexOf(column);
    const leftColumnIndex = table.columns.findLastIndex((column, index) => index < columnIndex && column.visible);
    const leftColumn = table.columns[leftColumnIndex];
    return leftColumn?.draggable ? leftColumnIndex : -1;
}

function getRightColumnIndex(table: ITable, column: ITableColumn) {
    const columnIndex = table.columns.indexOf(column);
    const rightColumnIndex = table.columns.findIndex((column, index) => index > columnIndex && column.visible);
    const rightColumn = table.columns[rightColumnIndex];
    return rightColumn?.draggable ? rightColumnIndex : -1;
}

function onResize(event: RequestUpdateType[]) {
    let eventIndex = 0;
    let columnIndex = 0;
    while (eventIndex < event.length) {
        const column = table.value.columns[columnIndex];
        columnIndex++;

        if (!column || !column.visible) {
            continue;
        }

        const update = event[eventIndex];
        column.width = update.size ?? 10;

        eventIndex++;
    }

    if (!debounceInstance) {
        debounceInstance = debounce((table: ITable) => {
            emit('update:table', table);
        }, 500);
    }

    debounceInstance(table.value);
}

function onScroll(event: Event) {
    const scroll = event.target as HTMLElement;
    isScrolled.value = scroll.scrollTop !== 0;
}
</script>

<style lang="scss">
.v-table {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;

    &__index {
        width: 2rem;
        margin: 0 0.75rem 0 0.5rem;
        flex-shrink: 0;
        color: var(--text-black-tertiary);
    }

    &__container {
        display: flex;
    }

    &__wrapper,
    &__container {
        flex-grow: 1;
        max-width: 100%;
    }

    &__header {
        display: flex;
        position: static;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 10;
        background-color: var(--background-color);

        &--with-shadow {
            box-shadow: 0 0.125rem 0.6875rem 0 rgba(0, 0, 0, 0.07);
        }
    }

    &__body {
        display: flex;
        width: 100%;
        max-height: 100%;
        flex-direction: column;
    }

    &__row {
        display: flex;
        flex-direction: row;
        margin: -0.0625rem 0 0 0;
        height: 1.875rem;
        line-height: 1.875rem;

        @include h7(false);
        color: var(--text-black-primary);
        cursor: pointer;

        &--header {
            position: relative;
            margin: 0;
            flex-grow: 1;
            width: 100%;
        }

        &--header-shadow {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            width: auto;
            user-select: none;
        }

        &--invisible {
            & .v-table__column {
                opacity: 0;
                visibility: hidden;
                z-index: -1;

                &:hover {
                    z-index: -1;
                }
            }
        }

        &--visible {
            & .v-table__column {
                opacity: 1;
            }
        }

        &--hover:hover {
            & .v-table__column {
                background-color: var(--background-tertiary);
            }
        }

        &--active {
            background: var(--background-color) !important;
            --background-color: var(--background-blue);

            & .v-table__column {
                background: var(--background-color);
                background-color: var(--background-blue) !important;
            }
        }
    }

    &__column {
        min-width: 8rem;
        margin: 0 -0.0625rem 0 0;
        overflow: hidden;

        &--indexed {
            min-width: 24rem;
            display: flex;
        }

        &--draggable {
            z-index: 1;
            user-select: none;
        }

        &--border,
        &--borderless {
            z-index: 2;
            border: 0.0625rem solid var(--background-tertiary);
            box-sizing: content-box;

            &:hover {
                z-index: 3;
                border-color: var(--background-quaternary);
                background-color: var(--background-tertiary);
            }
        }

        &--border-left-borderless,
        &--borderless:first-child {
            margin-left: 1rem;
            border-left-color: transparent;

            &:hover {
                margin-left: 0;
                padding-left: 1rem;
                border-left-color: transparent;
            }
        }

        &--border-right-borderless,
        &--borderless:last-child {
            margin-right: 1rem;
            border-right-color: transparent;

            &:hover {
                margin-right: 0;
                padding-right: 1rem;
                border-right-color: transparent;
            }
        }

        &--header {
            display: flex;
        }
    }
}
</style>
