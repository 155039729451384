<template>
    <div class="goal-panel">
        <div class="goal-panel__column goal-panel__column--primary">
            <div class="goal-panel__block goal-panel__block--center goal-panel__block--pillow" v-if="goal.archivedAt">
                <NoticeSvg class="goal-panel__alert-icon"></NoticeSvg>

                <h4 class="goal-panel__alert">
                    {{ t('goal-archived') }}
                </h4>
            </div>

            <div class="goal-panel__block goal-panel__block--semi-padding goal-panel__block--pillow">
                <div class="goal-panel__row">
                    <VTextarea
                        class="simple-textarea--h2"
                        :class="{
                            error: formErrors?.title,
                        }"
                        concurrency-mode
                        debounce-mode
                        :disabled="!canUpdate || disabled"
                        :editable="editable"
                        maxlength="512"
                        :model-value="goal.title"
                        required
                        @edit-request="confirmToDraft"
                        @update:model-value="changeTitleAsync(goal, $event)"
                    ></VTextarea>
                </div>

                <div class="goal-panel__row goal-panel__row--padding">
                    <VInlineLink
                        class="prevent-close"
                        :subject="t('board-link-text')"
                        :to="{
                            name: 'projects.board',
                            params: {
                                projectId: project.id,
                                boardId: board.id,
                            },
                        }"
                        v-if="project && board"
                    >
                        {{ tryTranslate(board.title) }}, {{ tryTranslate(project.title) }}
                    </VInlineLink>
                </div>

                <div class="goal-panel__row goal-panel__row--padding goal-panel__row--separate">
                    <ul class="info-panel goal-panel__info-panel">
                        <li class="info-panel__row">
                            <span
                                class="task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right"
                                >{{ t('author') }}</span
                            >

                            <div class="info-panel__content info-panel__content--vcenter">
                                <VUsersList
                                    :disabled="!canUpdateAuthor || disabled"
                                    :editable="canUpdateAuthor && !disabled"
                                    :users="[goal.author!]"
                                    @open="showAuthorSelector"
                                ></VUsersList>
                            </div>
                        </li>

                        <li class="info-panel__row">
                            <span
                                class="goal-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right"
                                >{{ t('status') }}</span
                            >

                            <div class="info-panel__content info-panel__content--vcenter info-panel__content--buttons">
                                <VTaskStatus class="status--space-right" :task="goal"></VTaskStatus>

                                <template v-for="(action, index) in actions" :key="index">
                                    <VButton
                                        :class="action.panelClasses"
                                        @click="action.action"
                                        v-if="action.isMainAction"
                                        >{{ action.title }}</VButton
                                    >
                                </template>

                                <VButtonDropdown :options="actions" placement="left-start" v-if="actions.length">
                                    <DotsSvg></DotsSvg>
                                </VButtonDropdown>
                            </div>
                        </li>

                        <li class="info-panel__row">
                            <span
                                class="goal-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right"
                                >{{ t('deadline') }}</span
                            >

                            <div class="info-panel__content">
                                <VDatePicker
                                    class="inline-50-x inline-fluid"
                                    :disabled="!canUpdate || disabled"
                                    :editable="editable"
                                    :model-value="goal.deadline"
                                    @edit-request="confirmToDraft"
                                    @update:model-value="changeDeadlineAsync(goal, $event)"
                                ></VDatePicker>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="goal-panel__row goal-panel__row--padding goal-panel__row--separate">
                    <span class="goal-panel__label goal-panel__label--separate required">
                        {{ t('description') }}
                    </span>

                    <VContentEditable
                        class="simple-contenteditable--great simple-contenteditable--unlimited inline-100-x inline-fluid"
                        :class="{
                            error: formErrors?.description,
                        }"
                        concurrency-mode
                        debounce-mode
                        :disabled="!canUpdate || disabled"
                        :editable="editable"
                        maxlength="4096"
                        :model-value="goal.description"
                        @edit-request="confirmToDraft"
                        @update:model-value="changeDescriptionAsync(goal, $event)"
                    ></VContentEditable>
                </div>

                <div class="goal-panel__row goal-panel__row--padding goal-panel__row--separate">
                    <span class="goal-panel__label goal-panel__label--separate-x2">
                        {{ t('attachments') }}
                    </span>

                    <VFileList
                        :attachments="attachments"
                        :queue="fileQueue"
                        :uploader="uploader"
                        @removed="removeStoryAsync(goal, $event)"
                        @uploaded="uploadedAttachmentHandler"
                    ></VFileList>
                </div>
            </div>

            <div
                class="goal-panel__block goal-panel__block--nesting goal-panel__block--no-padding goal-panel__block--collaborators goal-panel__block--fixed-height"
            >
                <div class="goal-panel__sub-block goal-panel__sub-block--pillow goal-panel__sub-block--collaborators">
                    <div class="goal-panel__button-block">
                        <VButton
                            class="goal-panel__button goal-panel__button--collaborators"
                            :class="{
                                'goal-panel__button--active': collaboratorsBlockRole === roles.Assignee,
                            }"
                            @click="showMangers"
                        >
                            {{ t('assignees') }}
                        </VButton>

                        <VButton
                            class="goal-panel__button goal-panel__button--collaborators"
                            :class="{
                                'goal-panel__button--active': collaboratorsBlockRole === roles.Collaborator,
                            }"
                            @click="showCollaborators"
                        >
                            {{ t('collaborators') }}
                        </VButton>
                    </div>

                    <VCollaboratorsList
                        :collaborators="goal.collaborators"
                        :disabled="!canManageCollaborators || disabled"
                        :editable="editable || collaboratorsBlockRole !== roles.Assignee"
                        fluid
                        multiple
                        :role="collaboratorsBlockRole"
                        :taskId="goal.id"
                        @added="addCollaboratorAsync(goal, $event)"
                        @edit-request="confirmToDraft"
                        @removed="removeCollaboratorAsync(goal, $event)"
                        @replaced="replaceCollaboratorAsync(goal, $event)"
                    ></VCollaboratorsList>
                </div>

                <VColumn
                    class="goal-panel__sub-block goal-panel__sub-block--no-padding goal-panel__sub-block--tasks"
                    :column="{
                        id: -1,
                        boardId: -1,
                        projectId: -1,
                        order: -1,
                        title: t('task', 3),
                    }"
                    :create-button="!disabled"
                    wheel-propagation
                    @create-empty-task="$emit('createEmptyTask', goal)"
                >
                    <ul class="column__list column__list--no-padding">
                        <template v-for="(task, taskIndex) in tasks" :key="taskIndex">
                            <li class="column__item">
                                <RouterLink :to="{ query: { task: task.id } }">
                                    <VTaskCard :task="task"></VTaskCard>
                                </RouterLink>
                            </li>
                        </template>
                    </ul>
                </VColumn>
            </div>

            <div
                id="approvers-block"
                ref="approversBlock"
                class="goal-panel__block goal-panel__block--pillow goal-panel__block--fixed-height goal-panel__block--flex"
                v-if="showApproversBlock"
            >
                <VUserSelector
                    v-model="showApproversBlockApprovers"
                    :cancel-button-title="t('cancel')"
                    deletable
                    multiple
                    :submit-button-title="t('send-for-approvement')"
                    :title="t('approvers')"
                    @cancel="hideApproverSelector"
                    @submit="startApprovementProcess"
                >
                </VUserSelector>
            </div>

            <div
                ref="authorBlock"
                class="goal-panel__block goal-panel__block--pillow goal-panel__block--fixed-height goal-panel__block--flex"
                v-if="showAuthorBlock"
            >
                <VUserSelector
                    v-model="showAuthorBlockAuthors"
                    :cancel-button-title="t('cancel')"
                    :clearable="false"
                    :submit-button-title="t('apply')"
                    :title="t('author')"
                    @cancel="hideAuthorSelector"
                    @submit="setAuthor"
                >
                </VUserSelector>
            </div>

            <div
                class="goal-panel__block goal-panel__block--pillow"
                v-if="goal.approvements && goal.approvements.length"
            >
                <h3 class="goal-panel__header goal-panel__header--title">{{ t('approval-list-title') }}</h3>

                <VApprovementList
                    :model-value="goal.approvements"
                    @approve="approveAsync(goal, $event)"
                    @disapprove="disapproveAsync(goal, $event)"
                ></VApprovementList>
            </div>
        </div>

        <div
            class="goal-panel__column goal-panel__column--secondary"
            :class="{ 'goal-panel__column--top-layer': $device.isTablet }"
            @click="$emit('update:showStories', $device.isTablet ? !showStories : showStories)"
            v-if="showStories"
        >
            <div
                class="goal-panel__block goal-panel__block--no-padding goal-panel__block--stories goal-panel__block--sticky goal-panel__block--pillow"
                @click.stop
            >
                <div class="goal-panel__stories">
                    <VButton
                        class="button--rounded button--transparent goal-panel__button goal-panel__button--close"
                        @click="$emit('update:showStories', !showStories)"
                    >
                        <TimesSvg></TimesSvg>
                    </VButton>

                    <h3 class="goal-panel__header goal-panel__header--selector">
                        <VSelect
                            v-model="storyViewMode"
                            class="v-select v-select--primary"
                            label="title"
                            :options="viewModeOptions"
                            :placeholder="t('default-placeholder', 2)"
                            :reduce="getOptionValue"
                            :searchable="false"
                        >
                        </VSelect>
                    </h3>

                    <VFeedStory
                        scrollable
                        selectable
                        :stories="goal.stories"
                        :task="goal"
                        title
                        :view-mode="storyViewMode"
                        @remove-story="removeStoryAsync(goal, $event)"
                        @start-edit-story="startEdit"
                    ></VFeedStory>
                </div>

                <VChatInput
                    v-model:editModel="editComment"
                    v-model:newModel="newComment"
                    v-focus
                    class="goal-panel__chat chat-input--shadow"
                    :uploader="uploader"
                    @start-edit="startEdit"
                    @stop-edit="stopEdit"
                    @submit="createComment"
                    @update="updateComment"
                    v-if="canComment"
                ></VChatInput>
            </div>
        </div>

        <VDropzone @drop="onFileDrop"></VDropzone>
    </div>
</template>

<script lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';
import TimesSvg from '@/assets/times.svg';
import NoticeSvg from '@/assets/notice.svg';

// Components
import VTaskStatus from './VTaskStatus.vue';
import VButton from './VButton.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VColumn from './VColumn.vue';
import VFileList from './VFileList.vue';
import VTaskCard from './VTaskCard.vue';
import VDropzone from './VDropzone.vue';
import VUsersList from './VUsersList.vue';
import VChatInput from './VChatInput.vue';
import VFeedStory from './VFeedStory.vue';
import VDatePicker from './VDatePicker.vue';
import VInlineLink from './VInlineLink.vue';
import VApprovementList from './VApprovementList.vue';
import VUserSelector from './VUserSelector.vue';
import VCollaboratorsList from './VCollaboratorsList.vue';
import VSelect from './VSelect.vue';
import VTextarea from './VTextarea.vue';
import VContentEditable from './VContentEditable.vue';

// Other
import ITask from '../core/Models/ITask';
import IUser from '@/core/Models/IUser';
import StoryType from '@/core/Values/StoryType';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import IAttachment from '@/core/Values/IAttachment';
import { fileExtension } from '@/utils/utils';
import Status from '@/core/Values/Status';
import IProject from '@/core/Models/IProject';
import IBoard from '@/core/Models/IBoard';
import IUploader from '@/core/Uploader/IUploader';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import IApiResult from '@/core/IApiResult';
import scrollIntoView from 'scroll-into-view';
import { debounce } from 'debounce';
import { defineComponent, ref, nextTick, markRaw } from 'vue';
import { PropType } from 'vue';
import { viewModeOptions } from '@/core/Values/StoryViewMode';
import { tryTranslate } from '../plugins/VueI18n';
import { useI18n } from 'vue-i18n';
import { useTaskActions, useTaskApi, useTaskChat, useTaskRights, validateGoalFields } from '@/mixins/TaskApi';
import { useRoute, useRouter } from 'vue-router';

type DebounceFunction = (() => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    components: {
        DotsSvg,
        TimesSvg,
        NoticeSvg,

        VTaskStatus,
        VButton,
        VButtonDropdown,
        VColumn,
        VTaskCard,
        VDropzone,
        VFileList,
        VUsersList,
        VChatInput,
        VFeedStory,
        VDatePicker,
        VInlineLink,
        VUserSelector,
        VApprovementList,
        VCollaboratorsList,
        VSelect,
        VTextarea,
        VContentEditable,
    },

    setup(props) {
        const goal = ref(props.goal);

        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();

        let fileQueue = ref([] as File[]);

        let formErrors = ref(null as Record<string, string> | null);
        let formErrorsDebounce = ref(null as DebounceFunction | null);

        let showApproversBlock = ref(false);
        let showApproversBlockApprovers = ref([] as IUser[]);

        let approversBlock = ref(null as HTMLElement | null);

        const api = useTaskApi();
        const chat = useTaskChat(goal);
        const rights = useTaskRights(goal);
        const actions = useTaskActions(
            goal,
            (task: ITask, callback?: () => void): void => {
                formErrors.value = validateGoalFields(task);

                if (!formErrors.value) {
                    callback?.();
                    formErrorsDebounce.value?.flush();
                    formErrorsDebounce.value = null;
                    return;
                }

                if (!formErrorsDebounce.value) {
                    formErrorsDebounce.value = debounce(
                        () => (formErrors.value = formErrorsDebounce.value = null),
                        3000,
                    ); // 3s
                }

                formErrorsDebounce.value();
            },
            (task: ITask): void => {
                showApproversBlock.value = true;
                showApproversBlockApprovers.value = task.approvements?.length
                    ? task.approvements
                          ?.filter((approvement) => approvement.approver)
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .map((collaborator) => collaborator.approver!) ?? []
                    : task.collaborators
                          ?.filter(
                              (collaborator) => collaborator.user && collaborator.role === CollaboratorRole.Assignee,
                          )
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          .map((collaborator) => collaborator.user!) ?? [];

                nextTick(() => {
                    const element = approversBlock.value ?? document.getElementById('approvers-block');
                    if (!element) {
                        return;
                    }

                    scrollIntoView(element, {
                        time: 250,
                    });
                });
            },
        );

        return {
            t,
            route,
            router,

            ...api,
            ...chat,
            ...rights,
            ...actions,

            tryTranslate,

            fileQueue,
            formErrors,
            formErrorsDebounce,
            showApproversBlock,
            showApproversBlockApprovers,
        };
    },

    props: {
        goal: { type: Object as PropType<ITask>, required: true },
        tasks: { type: Array as PropType<ITask[]>, required: true },
        uploader: { type: Object as PropType<IUploader<IApiResult<IObjectStoreModel>>>, required: true },
        project: { type: Object as PropType<IProject | null>, default: null },
        board: { type: Object as PropType<IBoard | null>, default: null },
        showStories: { type: Boolean, default: true },
    },

    data: () => ({
        viewModeOptions,

        goalSelectOptions: [] as ITask[],

        showAuthorBlock: false,
        showAuthorBlockAuthors: [] as IUser[],

        roles: CollaboratorRole,
        collaboratorsBlockRole: CollaboratorRole.Assignee as CollaboratorRole,
    }),

    computed: {
        isDraft(): boolean {
            return this.goal.status === Status.Draft;
        },

        isCompleted(): boolean {
            return this.goal.status === Status.Completed;
        },

        isInProgress(): boolean {
            return this.goal.status === Status.InProgress;
        },

        managers(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Assignee);
        },

        contributors(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Contributor);
        },

        collaborators(): IUser[] {
            return this.getCollaboratorsWithRole(CollaboratorRole.Collaborator);
        },

        attachments(): IAttachment[] {
            if (!this.goal.stories) {
                return [];
            }

            return this.goal.stories
                .filter((story) => story.type === StoryType.Attachment)
                .map(
                    (attachment): IAttachment => ({
                        id: attachment.id,
                        authorId: attachment.actorId,
                        fileName: attachment.fileName,
                        objectName: attachment.objectName,
                        extension: fileExtension(attachment.fileName),
                        downloadUri: attachment.downloadUri,
                    }),
                );
        },
    },

    methods: {
        onFileDrop(files: File[]) {
            for (const file of files) {
                this.fileQueue.push(markRaw(file));
            }
        },

        showMangers(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Assignee);
        },

        showCollaborators(): void {
            this.showCollaboratorsWithRole(CollaboratorRole.Collaborator);
        },

        showCollaboratorsWithRole(role: CollaboratorRole): void {
            this.collaboratorsBlockRole = role;
        },

        startApprovementProcess(approvers: IUser[]): void {
            this.showApproversBlock = false;
            this.startApprovementProcessAsync(
                this.goal,
                approvers.map((approver) => approver.id),
            );
        },

        hideAuthorSelector(): void {
            this.showAuthorBlock = false;
            this.showAuthorBlockAuthors = [];
        },

        showAuthorSelector(): void {
            if (!this.goal.author) {
                return;
            }

            this.showAuthorBlock = true;
            this.showAuthorBlockAuthors = [this.goal.author];

            this.$nextTick(() => {
                if (!this.$refs.authorBlock) {
                    return;
                }

                scrollIntoView(this.$refs.authorBlock as HTMLElement, {
                    time: 250,
                });
            });
        },

        hideApproverSelector(): void {
            this.showApproversBlock = false;
            this.showApproversBlockApprovers = [];
        },

        getGoalId(goal: ITask): number {
            return goal.id;
        },

        getOptionValue(option: { value: number }): number {
            return option.value;
        },

        getCollaboratorsWithRole(role: CollaboratorRole): IUser[] {
            return (
                this.goal.collaborators?.reduce((result, collaborator) => {
                    if (collaborator.user && collaborator.role === role) {
                        result.push(collaborator.user);
                    }

                    return result;
                }, [] as IUser[]) ?? []
            );
        },

        setAuthor(authors: IUser[]): void {
            if (authors.length <= 0) {
                return;
            }

            this.showAuthorBlock = false;
            this.changeAuthorAsync(this.goal, authors[0].id);
        },

        applyRouteAction(): void {
            const action = this.route.query.action;

            switch (action) {
                case 'validate':
                    this.validate(this.goal);
                    break;
                case 'select-approvers':
                    this.showApproverSelector(this.goal);
                    break;
            }

            if (action) {
                this.router.replace({ query: { ...this.route.query, action: undefined } });
            }
        },
    },

    watch: {
        '$route.query.action': {
            handler: 'applyRouteAction',
        },
    },

    mounted() {
        this.applyRouteAction();
    },
});
</script>

<style lang="scss">
.goal-panel {
    display: flex;

    &__row {
        &--padding {
            padding: 0 1rem;
        }

        &--separate {
            margin-top: 1.25rem;
        }

        &--double-separate {
            margin-top: 3.5rem;
            margin-bottom: 3.5rem;
        }
    }

    &__label {
        @include h7(false);
        @include overflow-ellipsis();

        color: var(--text-black-secondary);

        &--separate {
            margin-bottom: 0.25rem;
        }

        &--separate-x2 {
            margin-bottom: 0.5rem;
        }
    }

    &__alert {
        margin: 0;

        @include h4();
        color: var(--color-negative);
    }

    &__alert-icon {
        width: 1.75rem;
        height: 1.75rem;
        margin-right: 0.25rem;

        color: var(--color-negative);
    }

    &__column {
        position: relative;

        &--primary {
            max-width: 80rem;
            flex: 1 1;
            padding: 0.5rem 1.25rem 0;
            overflow: hidden;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                padding: 0.5rem 1.5rem 0 3.5rem;
            }
        }

        &--secondary {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            z-index: 1;
            background-color: var(--background-color);
            --background-color: rgba(0, 0, 0, 0.3);

            @media (min-width: #{$breakpoint-desktop + 1}) {
                position: static;
                top: unset;
                left: unset;
                right: unset;
                bottom: unset;
                width: 35%;
                padding-right: 3.5rem;
                background-color: var(--background-color);
                --background-color: none;
            }
        }

        &--top-layer {
            z-index: 100001;
        }
    }

    &__block,
    &__sub-block {
        padding: 2rem 2rem 2rem 2rem;

        &--center {
            display: flex;
            align-items: center;
        }

        &--semi-padding {
            padding: 1rem 1rem 2rem 1rem;
        }

        &--pillow {
            border-radius: 1.5rem;
            border-left: 0.0625rem solid var(--background-tertiary);
            background: var(--background-color);
            --background-color: var(--background-primary);
            box-shadow: 0 0 0.6875rem 0 rgba(0, 0, 0, 0.04);
        }

        &--no-padding {
            padding: 0;
        }
    }

    &__block {
        margin-bottom: 1.5rem;

        &--flex {
            display: flex;
        }

        &--stories {
            display: flex;
            margin-left: auto;
            height: 100%;
            padding: 0;
            flex-direction: column;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                max-width: 28rem;
            }

            @media (min-width: #{$breakpoint-desktop + 1}) {
                height: auto;
                max-width: none;
                margin-left: 0;
            }
        }

        &--sticky {
            @media (min-width: #{$breakpoint-desktop + 1}) {
                position: static;
                position: sticky;
                top: 0.5rem;
                left: 0;
                right: 0;
                height: 75vh;
            }
        }

        &--stories.goal-panel__block--pillow {
            border-radius: 0;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            @media (min-width: #{$breakpoint-desktop + 1}) {
                border-radius: 1.5rem;
            }
        }

        &--collaborators {
            padding: 0;
        }

        &--fixed-height {
            min-height: 30rem;
        }

        &--nesting {
            display: flex;
            gap: 1.5rem;
            flex-wrap: wrap;
        }
    }

    &__sub-block {
        display: flex;
        max-width: 100%;

        &--tasks {
            width: 100%;
            height: auto;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                width: auto;
                max-height: 40rem;
                min-width: 18.75rem;
            }
        }

        &--collaborators {
            display: flex;
            flex-direction: column;
            padding: 2rem 2rem 1.5rem 2rem;
            flex-grow: 1;
        }
    }

    &__button-block {
        display: flex;
        margin: 0 0 2rem 0;

        border-radius: 1.5rem;
        background: var(--background-color);
        --background-color: var(--background-secondary);
    }

    &__button {
        &--collaborators {
            width: 50%;
            justify-content: center;
            padding: 0.5rem 1.25rem;

            @include button-primary();
            @include overflow-ellipsis();
            border-radius: 1.25rem;
            color: var(--text-black-primary);
        }

        &--active {
            background: var(--background-color);
            --background-color: var(--brand-green);
            color: var(--text-white-primary);
        }

        &--close {
            position: absolute;
            top: 1.125rem;
            right: 0.8125rem;
            padding: 0.5rem;
            z-index: 1;

            color: var(--text-black-primary);
            background-color: var(--background-primary);

            @media (min-width: #{$breakpoint-tablet + 1}) {
                display: none;
            }
        }
    }

    &__chat {
        max-height: 24rem;
        overflow: hidden;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }

    &__stories {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 0;
        padding: 1rem 0 0 0;
    }

    &__stories-title {
        margin: 1.5rem 0 1.125rem 1.5rem;
        @include h3();
    }

    &__header {
        margin: 0 0.75rem 1.25rem 0.75rem;

        &--title {
            @include h3();

            color: var(--text-black-primary);
        }

        &--selector {
            & .vs__selected {
                @include h3();
            }
        }
    }
}
</style>
