import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "chat-input"
}
const _hoisted_2 = { class: "chat-input__row chat-input__row--attachments" }
const _hoisted_3 = { class: "chat-input__input" }

import SendSvg from '@/assets/send.svg';
import EditSvg from '@/assets/edit.svg';

// Components
import VButton from './VButton.vue';
import VUserCard from './VUserCard.vue';
import VContentEditable from './VContentEditable.vue';

// Other
import IChatComment from '@/core/Values/IChatComment';
import store from '@/store';
import VFileList from './VFileList.vue';
import UploadableFile from '@/core/Uploader/UploadableFile';
import IApiResult from '@/core/IApiResult';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import IUploader from '@/core/Uploader/IUploader';
import IAttachment from '@/core/Values/IAttachment';
import { computed, markRaw, PropType, ref, watch } from 'vue';
import UploadableFileStatus from '@/core/Uploader/UploadableFileStatus';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VChatInput',
  props: {
    newModel: { type: Object as PropType<IChatComment>, required: true },
    editModel: { type: Object as PropType<IChatComment | null>, default: () => null },
    uploader: { type: Object as PropType<IUploader<IApiResult<IObjectStoreModel>>>, required: true },
},
  emits: ['update:editModel', 'update:newModel', 'stop-edit', 'start-edit', 'update', 'submit'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const emit = __emit;

const props = __props;

const uploadables = ref([] as UploadableFile<IApiResult<IObjectStoreModel>>[]);
const queue = ref([] as File[]);
const currentUser = store.state.user;
const textarea = ref(null as unknown as { focus: () => void });

let isEdit = computed((): boolean => !!props.editModel?.id && props.editModel.id !== -1);

const currentValue = computed({
    get: () => (isEdit.value ? props.editModel : props.newModel),
    set: (value) => emit(props.editModel ? 'update:editModel' : 'update:newModel', value),
});

function submit() {
    const eventName = isEdit.value ? 'update' : 'submit';

    if (
        uploadables.value.some(
            (uploadable) =>
                uploadable.status === UploadableFileStatus.UPLOADING ||
                uploadable.status === UploadableFileStatus.WAITING,
        )
    ) {
        return;
    }

    emit(eventName, currentValue.value);

    if (!isEdit.value) {
        currentValue.value = {
            id: -1,
            text: '',
            attachments: [],
        };
    }
}

watch(
    () => props.editModel,
    () => {
        if (!props.editModel) {
            return;
        }

        focus();
    },
);

function focus() {
    textarea.value?.focus();
}

function stopEdit() {
    emit('stop-edit');
}

function startEdit() {
    if (currentValue.value?.text || currentValue.value?.attachments.length) {
        return;
    }

    emit('start-edit');
}

function pasted(event: ClipboardEvent & { originalEvent: ClipboardEvent }) {
    event.preventDefault();

    if (isEdit.value) {
        return;
    }

    const items = (event.clipboardData || event.originalEvent.clipboardData)?.items;

    if (!items) {
        return;
    }

    for (let index = 0; index < items.length; index++) {
        const item = items[index];

        if (item.kind !== 'file') {
            continue;
        }

        const file = item.getAsFile();

        if (!file) {
            continue;
        }

        queue.value.push(markRaw(file));
    }
}

function removedAttachmentHandler(attachment: IAttachment) {
    var index = currentValue.value?.attachments.indexOf(attachment) ?? -1;

    if (index === -1) {
        return;
    }

    currentValue.value?.attachments.splice(index, 1);
}

function uploadedAttachmentHandler(uploadable: UploadableFile<IApiResult<IObjectStoreModel>>) {
    if (!uploadable.model) {
        return;
    }

    currentValue.value?.attachments.push({
        id: -1,
        authorId: currentUser?.id,
        fileName: uploadable.name,
        objectName: uploadable.model.data.objectName,
        extension: uploadable.extension,
        downloadUri: uploadable.model.data.downloadUri,
    });
}

return (_ctx: any,_cache: any) => {
  return (currentValue.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(VFileList, {
            attachments: currentValue.value.attachments,
            class: "file-list--nowrap",
            "hide-add-button": "",
            queue: queue.value,
            uploadables: uploadables.value,
            uploader: __props.uploader,
            onRemoved: removedAttachmentHandler,
            onUploaded: uploadedAttachmentHandler
          }, null, 8, ["attachments", "queue", "uploadables", "uploader"])
        ]),
        _createElementVNode("form", {
          class: "chat-input__row chat-input__row--input",
          onSubmit: _withModifiers(submit, ["prevent"])
        }, [
          _createVNode(VUserCard, {
            class: "chat-input__hover chat-input__hover--left",
            "picture-only": "",
            user: _unref(currentUser)
          }, null, 8, ["user"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(VContentEditable, {
              ref_key: "textarea",
              ref: textarea,
              modelValue: currentValue.value.text,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentValue.value.text) = $event)),
              class: "simple-contenteditable--borderless",
              placeholder: _unref(t)('your-comment'),
              onKeydown: [
                _withKeys(stopEdit, ["esc"]),
                _withKeys(startEdit, ["up"])
              ],
              onPaste: pasted,
              onSubmit: submit
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _createVNode(VButton, { class: "button--circle button--green chat-input__hover chat-input__hover--right" }, {
            default: _withCtx(() => [
              (_unref(isEdit))
                ? (_openBlock(), _createBlock(_unref(EditSvg), { key: 0 }))
                : (_openBlock(), _createBlock(_unref(SendSvg), { key: 1 }))
            ]),
            _: 1
          })
        ], 32)
      ]))
    : _createCommentVNode("", true)
}
}

})