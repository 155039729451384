import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, unref as _unref, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "button button--rounded button--transparent button--dropdown" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "button__empty-icon"
}

import VPopper from 'vue3-popper';

// Other
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { PropType } from 'vue';

type Placement =
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';


export default /*@__PURE__*/_defineComponent({
  ...{
    inheritAttrs: false,
},
  __name: 'VButtonDropdown',
  props: {
    options: { type: Array as PropType<IDropdownOption[]>, required: true },
    placement: { type: String as PropType<Placement>, default: 'bottom-end' },
},
  setup(__props) {

// Svg
// ...

// Components




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VPopper), _mergeProps({
    "offset-distance": "3",
    placement: __props.placement,
    "z-index": "10000"
  }, _ctx.$attrs), {
    content: _withCtx(({ close }) => [
      _createElementVNode("ul", {
        class: "button__dropdown-menu",
        onClick: close
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: _normalizeClass(["button button__dropdown-option", option.classes]),
            onClick: ($event: any) => (option.action?.(option, $event))
          }, [
            (option.icon)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), { key: 0 }))
              : (_openBlock(), _createElementBlock("span", _hoisted_4)),
            _createTextVNode(" " + _toDisplayString(option.title), 1)
          ], 10, _hoisted_3))
        }), 128))
      ], 8, _hoisted_2)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "button", {}, () => [
        _createElementVNode("button", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ]),
    _: 3
  }, 16, ["placement"]))
}
}

})