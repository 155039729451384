import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-page-header__column app-page-header__column--tools" }
const _hoisted_2 = { class: "column__list" }
const _hoisted_3 = { class: "column__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VPageSettings = _resolveComponent("VPageSettings")!
  const _component_VTaskFilter = _resolveComponent("VTaskFilter")!
  const _component_DotsSvg = _resolveComponent("DotsSvg")!
  const _component_VButtonDropdown = _resolveComponent("VButtonDropdown")!
  const _component_VSearchField = _resolveComponent("VSearchField")!
  const _component_VPageHeader = _resolveComponent("VPageHeader")!
  const _component_VTaskCard = _resolveComponent("VTaskCard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_VColumn = _resolveComponent("VColumn")!
  const _component_VGoalCard = _resolveComponent("VGoalCard")!
  const _component_VBoard = _resolveComponent("VBoard")!
  const _component_VTableRow = _resolveComponent("VTableRow")!
  const _component_VTableDataSet = _resolveComponent("VTableDataSet")!
  const _component_VTable = _resolveComponent("VTable")!
  const _component_VPageContent = _resolveComponent("VPageContent")!
  const _component_VPage = _resolveComponent("VPage")!

  return (_openBlock(), _createBlock(_component_VPage, {
    class: _normalizeClass({ 'app-page--fullscreen': _ctx.pageSettings.fullscreen })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VPageHeader, {
        "show-third-block": "",
        title: _ctx.t('approvements')
      }, {
        second: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_VPageSettings, {
              modelValue: _ctx.pageSettings,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageSettings) = $event)),
              class: "prevent-close",
              options: _ctx.pageOptions
            }, null, 8, ["modelValue", "options"]),
            _createVNode(_component_VTaskFilter, {
              modelValue: _ctx.filters,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
              class: "prevent-close",
              "filter-types": [
                            _ctx.TaskFilterTypes.Author,
                            _ctx.TaskFilterTypes.Assignee,
                            _ctx.TaskFilterTypes.Approver,
                            _ctx.TaskFilterTypes.Contributor,
                            _ctx.TaskFilterTypes.AuthorOrAssignee,
                            _ctx.TaskFilterTypes.Deadline,
                            _ctx.TaskFilterTypes.CreationDate,
                        ]
            }, null, 8, ["modelValue", "filter-types"]),
            _createVNode(_component_VButtonDropdown, {
              class: "prevent-close",
              options: _ctx.regularActions,
              placement: "left-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DotsSvg, { class: "rotate-90" })
              ]),
              _: 1
            }, 8, ["options"]),
            _createVNode(_component_VSearchField, {
              modelValue: _ctx.searchString,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchString) = $event)),
              class: "prevent-close",
              "debounce-mode": ""
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_VPageContent, {
        vertical: _ctx.pageSettings.viewMode === 'table'
      }, {
        default: _withCtx(() => [
          (_ctx.pageSettings.viewMode === 'board')
            ? (_openBlock(), _createBlock(_component_VBoard, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_VColumn, {
                    column: {
                        id: -1,
                        order: -1,
                        boardId: -1,
                        projectId: -1,
                        title: _ctx.t('tasks'),
                    },
                    "wheel-propagation": ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("ul", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: task.id,
                            class: "column__item"
                          }, [
                            _createVNode(_component_RouterLink, {
                              to: { query: { task: task.id } }
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_VTaskCard, { task: task }, null, 8, ["task"])
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }, 8, ["column"]),
                  _createVNode(_component_VColumn, {
                    class: "column--goals",
                    column: {
                        id: -1,
                        order: -1,
                        boardId: -1,
                        projectId: -1,
                        title: _ctx.t('goals'),
                    },
                    "wheel-propagation": ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("ul", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goals, (goal) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: goal.id,
                            class: "column__item"
                          }, [
                            _createVNode(_component_RouterLink, {
                              to: { name: 'goals.view', params: { goalId: goal.id } }
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_VGoalCard, { goal: goal }, null, 8, ["goal"])
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }, 8, ["column"])
                ]),
                _: 1
              }))
            : (_ctx.pageSettings.viewMode === 'table')
              ? (_openBlock(), _createBlock(_component_VTable, {
                  key: 1,
                  order: _ctx.order,
                  "onUpdate:order": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.order) = $event)),
                  table: _ctx.table,
                  "onUpdate:table": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.table) = $event)),
                  resetOrder: _ctx.resetOrder
                }, {
                  default: _withCtx(() => [
                    (_ctx.tasks.length)
                      ? (_openBlock(), _createBlock(_component_VTableDataSet, {
                          key: 0,
                          title: _ctx.t('tasks')
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task, index) => {
                              return (_openBlock(), _createBlock(_component_RouterLink, {
                                key: task.id,
                                class: "prevent-close",
                                to: { query: { task: task.id } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_VTableRow, {
                                    active: task.id.toString() == _ctx.route.query.task,
                                    data: task,
                                    index: index,
                                    table: _ctx.table,
                                    onContextmenu: ($event: any) => (_ctx.openContextMenu($event, task))
                                  }, null, 8, ["active", "data", "index", "table", "onContextmenu"])
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true),
                    (_ctx.goals.length)
                      ? (_openBlock(), _createBlock(_component_VTableDataSet, {
                          key: 1,
                          title: _ctx.t('goals')
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goals, (goal, index) => {
                              return (_openBlock(), _createBlock(_component_RouterLink, {
                                key: goal.id,
                                to: { name: 'goals.view', params: { goalId: goal.id } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_VTableRow, {
                                    active: goal.id.toString() == _ctx.route.query.task,
                                    data: goal,
                                    index: index,
                                    table: _ctx.table,
                                    onContextmenu: ($event: any) => (_ctx.openContextMenu($event, goal))
                                  }, null, 8, ["active", "data", "index", "table", "onContextmenu"])
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["title"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["order", "table", "resetOrder"]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["vertical"])
    ]),
    _: 1
  }, 8, ["class"]))
}