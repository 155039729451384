import { createApp } from 'vue';
import App from './App.vue';
import AppError from './AppError.vue';
import router from './router';
import store from './store';
import VueI18n from './plugins/VueI18n';
import VCustomDirectives from './plugins/VCustomDirectives';
import VDeviceDetector from './plugins/VDeviceDetector';
import FloatingVue from 'floating-vue';
import Application from './core/Application';
import ContextMenu from '@imengyu/vue3-context-menu';
import { $error } from './utils/app-utils';
import './plugins/DragEventEmitter';

Application.authAsync()
    .then((authenticated: boolean) => {
        if (!authenticated) {
            return;
        }

        createApp(App)
            .use(store)
            .use(VueI18n)
            .use(FloatingVue)
            .use(VCustomDirectives)
            .use(VDeviceDetector)
            .use(ContextMenu)
            .use(router)
            .mount('#app');
    })
    .catch((error) => {
        createApp(AppError).use(store).use(VueI18n).mount('#app');
        $error(error);
    });
