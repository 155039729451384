<template>
    <div ref="dropzone" class="dropzone" v-show="show">
        <div class="dropzone__area">
            <span class="dropzone__text">{{ props.message || t('attach-files') }}</span>
        </div>
    </div>
</template>
<script setup lang="ts">
import DataTransferExtractor from '@/core/DataTransferExtractor';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import { $error } from '@/utils/app-utils';
import { defineProps, defineEmits, onBeforeMount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['drop']);
const props = defineProps({
    message: { type: String, default: '' },
});

let show = ref(false);
let dropzone = ref(null as HTMLDivElement | null);

function showDropzone() {
    show.value = true;
}

function hideDropzone() {
    show.value = false;
}

function dropHandler(event: DragEvent) {
    if (!event.dataTransfer) {
        return;
    }

    try {
        const files = DataTransferExtractor.extract(event.dataTransfer);
        emit('drop', files);
    } catch (error) {
        $error(error);
    }
}

onMounted(() => {
    if (dropzone.value) {
        dropzone.value.addEventListener('drop', dropHandler, false);
    }

    emitter.on(EventNames.FileEnterWindow, showDropzone);
    emitter.on(EventNames.FileLeaveWindow, hideDropzone);
});

onBeforeMount(() => {
    if (dropzone.value) {
        dropzone.value.removeEventListener('drop', dropHandler, false);
    }

    emitter.off(EventNames.FileEnterWindow, showDropzone);
    emitter.off(EventNames.FileLeaveWindow, hideDropzone);
});
</script>
<style lang="scss">
:root {
    --dropzone-border-color: #72a7d0;
    --dropzone-background-color: rgba(0, 120, 215, 0.12);
}

.dropzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0.3125rem;
    z-index: 100000;

    &__area {
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 0.9375rem;
        border: 0.25rem dashed var(--dropzone-border-color);
        background-color: var(--dropzone-background-color);
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }

    &__text {
        @include h1();
    }
}
</style>
