import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["s"]
const _hoisted_2 = { class: "notification-card__row notification-card__row--header" }
const _hoisted_3 = { class: "notification-card__timestamp" }
const _hoisted_4 = { class: "notification-card__row notification-card__row--title" }
const _hoisted_5 = { class: "notification-card__row notification-card__row--content" }
const _hoisted_6 = { class: "notification-card__row notification-card__row--footer" }

import DotsSvg from '@/assets/dots.svg';
import RemoveSvg from '@/assets/remove.svg';

// Components
import VUserCard from './VUserCard.vue';
import VInlineLink from './VInlineLink.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VNotificationCardContentDefault from './VNotificationCardContentDefault.vue';

// Other
import INotification from '@/core/Models/INotification';
import { formatWithTime } from '@/utils/date-utils';
import store from '@/store';
import TaskType from '@/core/Values/TaskType';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { markRaw, PropType, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'VNotificationCard',
  props: {
    notification: { type: Object as PropType<INotification>, required: true },
},
  emits: ['remove'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const route = useRoute();
const emit = __emit;
const props = __props;

const isRead = computed((): boolean => !!props.notification.readAt);
const isGoal = computed((): boolean => props.notification.payload?.task?.type === TaskType.Goal);
const isSelected = computed((): boolean => props.notification.id == route.query.selected);
const createdAt = computed(() => formatWithTime(props.notification.createdAt));

const template = computed(() => {
    switch (props.notification.template) {
        default:
            return markRaw(VNotificationCardContentDefault);
    }
});

const gotoLink = computed(() => {
    const taskId = props.notification.payload?.taskId;

    if (isGoal.value) {
        return { name: 'goals.view', params: { goalId: taskId } };
    }

    return { query: { task: taskId, selected: props.notification.id } };
});

const dropdownOptions = computed((): IDropdownOption[] => {
    return [
        {
            icon: markRaw(RemoveSvg),
            title: t('remove-notification'),
            action: () => emit('remove', props.notification),
        },
    ];
});

const board = computed(() =>
    store.state.boards?.find((board) => board.id === props.notification.payload?.task?.boardId),
);

const project = computed(() =>
    store.state.projects?.find((project) => project.id === props.notification.payload?.task?.projectId),
);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notification-card prevent-close", {
            'notification-card--unread': !isRead.value,
            'notification-card--selected': isSelected.value,
        }]),
    s: __props.notification.id
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(VUserCard, {
        user: __props.notification.payload.actor
      }, null, 8, ["user"]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(createdAt.value), 1)
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(__props.notification.payload?.task?.title), 1),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(template.value), { notification: __props.notification }, null, 8, ["notification"]))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (project.value && board.value)
        ? (_openBlock(), _createBlock(VInlineLink, {
            key: 0,
            class: "prevent-close",
            subject: _unref(t)('board-link-text'),
            to: {
                    name: 'projects.board',
                    params: {
                        projectId: project.value.id,
                        boardId: board.value.id,
                    },
                    query: {
                        task: __props.notification.payload?.task?.id,
                    },
                },
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tryTranslate(board.value.title)) + ", " + _toDisplayString(_ctx.$tryTranslate(project.value.title)), 1)
            ]),
            _: 1
          }, 8, ["subject", "to"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "notification-card__actions",
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(VButtonDropdown, {
          options: dropdownOptions.value,
          placement: "bottom-end"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DotsSvg))
          ]),
          _: 1
        }, 8, ["options"]),
        _createVNode(_component_RouterLink, {
          class: "button button--secondary button--green",
          to: gotoLink.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(isGoal.value ? _unref(t)('goto-goal') : _unref(t)('goto-task')), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ], 10, _hoisted_1))
}
}

})