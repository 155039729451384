<template>
    <div
        ref="scroll"
        class="app-page-content app-page-content--scroll"
        :class="{
            'app-page-content--align-center': alignCenter,
            'app-page-content--justify-center': justifyCenter,
            'app-page-content--vertical': vertical,
            'app-page-content--horizontal': horizontal,
        }"
        @scroll="onScroll"
    >
        <slot></slot>
    </div>
</template>

<script lang="ts">
// Svg
// ...

// Components

// Other
import { defineComponent } from 'vue';

type DebounceFunction = (() => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    props: {
        alignCenter: { type: Boolean, default: false },
        justifyCenter: { type: Boolean, default: false },
        vertical: { type: Boolean, default: false },
    },
    data: () => ({
        scrollYNearEnd: false,
        debounce: null as DebounceFunction | null,
    }),
    methods: {
        onScroll(event: Event) {
            const scroll = event.target as HTMLDivElement;

            const prevScrollYNearEnd = this.scrollYNearEnd;
            this.scrollYNearEnd = scroll.scrollHeight - scroll.scrollTop - 400 < scroll.clientHeight;

            if (!prevScrollYNearEnd && this.scrollYNearEnd) {
                this.$emit('scroll-y-near-end');
            }
        },
    },
    computed: {
        horizontal(): boolean {
            return !this.vertical;
        },
    },
});
</script>

<style lang="scss">
.app-page-content {
    display: flex;
    height: 100%; // DON'T TOUCH
    min-height: 0; // DON'T TOUCH

    &--scroll {
        overflow: auto;
    }

    &--align-center {
        align-items: center;
    }

    &--justify-center {
        justify-content: center;
    }

    &--horizontal {
        height: 100%;
    }

    &--vertical {
        flex-direction: column;
    }
}
</style>
