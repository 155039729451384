import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-page-header__column app-page-header__column--tools" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VSearchField = _resolveComponent("VSearchField")!
  const _component_VPageHeader = _resolveComponent("VPageHeader")!
  const _component_VPageContent = _resolveComponent("VPageContent")!
  const _component_VPage = _resolveComponent("VPage")!

  return (_openBlock(), _createBlock(_component_VPage, null, {
    default: _withCtx(() => [
      _createVNode(_component_VPageHeader, {
        "show-third-block": "",
        title: _ctx.title
      }, {
        second: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_VSearchField, {
              modelValue: _ctx.searchString,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event)),
                _ctx.applySearchFilter
              ],
              class: "prevent-close",
              "debounce-mode": ""
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_VPageContent, { vertical: "" })
    ]),
    _: 1
  }))
}