import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "project-navigator" }
const _hoisted_2 = { class: "project-navigator__project-title" }
const _hoisted_3 = { class: "project-navigator__board-list" }

import DotsSvg from '@/assets/dots.svg';

// Components
import VButton from './VButton.vue';

// Other
import IProject from '../core/Models/IProject';
import IBoard from '@/core/Models/IBoard';
import store from '@/store';
import orderBy from 'lodash.orderby';
import { computed, PropType } from 'vue';
import { tryTranslate } from '../plugins/VueI18n';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'VProjectNavigator',
  props: {
    project: { type: Object as PropType<IProject>, required: true },
},
  setup(__props) {

// Svg
const route = useRoute();
const props = __props;

const boards = computed(
    (): IBoard[] => store.state.boards?.filter((board) => board.projectId === props.project.id) ?? [],
);
const boardsVisible = computed(() => props.project.id === parseInt(route.params.projectId?.toString(), 10));
const sortedProjectBoards = computed((): IBoard[] =>
    orderBy(boards.value, (board) => [100000 - board.type, board.order, tryTranslate(board.title)]),
);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_RouterLink, {
        class: "project-navigator__project project-navigator__project--toggle",
        tabindex: "0",
        to: {
                    name: 'project.view',
                    params: {
                        projectId: __props.project.id,
                    },
                }
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("span", {
            class: "project-navigator__project-icon",
            style: {
                        'background-color': '#80c342',
                    }
          }, null, -1)),
          _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$tryTranslate(__props.project.title)), 1),
          false
            ? (_openBlock(), _createBlock(VButton, {
                key: 0,
                class: "button--transparent project-navigator__project-options"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DotsSvg))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["to"]),
      _withDirectives(_createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedProjectBoards.value, (board) => {
          return (_openBlock(), _createElementBlock("li", {
            key: board.id,
            class: "project-navigator__board-item"
          }, [
            _createVNode(_component_RouterLink, {
              class: "project-navigator__board",
              to: {
                                name: 'projects.board',
                                params: {
                                    projectId: __props.project.id,
                                    boardId: board.id,
                                },
                            }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$tryTranslate(board.title)), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ], 512), [
        [_vShow, boardsVisible.value]
      ])
    ])
  ]))
}
}

})