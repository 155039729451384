import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "column__row column__row--header" }
const _hoisted_3 = {
  key: 0,
  class: "column__row column__row--content"
}
const _hoisted_4 = {
  key: 1,
  class: "column__scroll"
}

import PlusSvg from '@/assets/plus.svg';

// Components
import VInput from './VInput.vue';

// Other
import IColumn from '@/core/Models/IColumn';
import { debounce } from 'debounce';
import { nextTick, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VColumn',
  props: {
    column: { type: Object as PropType<IColumn>, required: true },
    blurry: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    noScrolling: { type: Boolean, default: false },
    createButton: { type: Boolean, default: false },
    wheelPropagation: { type: Boolean, default: false },
},
  emits: ['set-title', 'create-empty-task'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const emit = __emit;
const props = __props;

const renderScrollbar = ref(false);
const setTitle = debounce((newValue: string) => {
    emit('set-title', newValue);
}, 500);

if (props.noScrolling) {
    renderScrollbar.value = true;
} else {
    // Do you really wanna know why im doing that?
    nextTick(() => (renderScrollbar.value = true));
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.editable)
        ? (_openBlock(), _createBlock(VInput, {
            key: 0,
            class: "input-field--primary input-field--inline-50 column__title",
            "concurrency-mode": "",
            "debounce-mode": "",
            maxlength: "64",
            "model-value": __props.column.title,
            placeholder: _unref(t)('enter-title'),
            "onUpdate:modelValue": _unref(setTitle)
          }, null, 8, ["model-value", "placeholder", "onUpdate:modelValue"]))
        : (_openBlock(), _createElementBlock("h4", {
            key: 1,
            class: _normalizeClass(["column__title column__title--text", {
                        'column__title--blurry': __props.blurry,
                    }])
          }, _toDisplayString(__props.column.title ? __props.column.title : _unref(t)('untitled')), 3)),
      (__props.createButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "button button--rounded button--transparent column__button column__button--action inline-space-100-left",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('create-empty-task')))
          }, [
            _createVNode(_unref(PlusSvg))
          ]))
        : _createCommentVNode("", true)
    ]),
    (renderScrollbar.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (__props.noScrolling)
            ? _renderSlot(_ctx.$slots, "default", { key: 0 })
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default")
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})