<template>
    <div class="app-sidebar" v-show="opened || !$device.isMobile">
        <div class="app-sidebar__block app-sidebar__block--brand app-sidebar__block--separate-top">
            <SidebarBrandSvg v-if="!winterMode"></SidebarBrandSvg>
            <SidebarBrandWinterSvg v-else></SidebarBrandWinterSvg>

            <VButton class="button--rounded button--transparent app-sidebar__close-button" @click="closeSidebar">
                <TimesSvg></TimesSvg>
            </VButton>
        </div>

        <div
            class="app-sidebar__block app-sidebar__block--gap app-sidebar__block--padding app-sidebar__block--separate-top"
            v-if="canCreateTasks"
        >
            <VButton
                class="button button--primary button--default button--center button--fluid"
                :disabled="isLocked"
                @click="createEmptyGoal"
            >
                {{ t('create-goal') }}
            </VButton>

            <VButton
                class="button button--primary button--default button--center button--fluid"
                :disabled="isLocked"
                @click="createEmptyTask"
            >
                {{ t('create-task') }}
            </VButton>
        </div>

        <nav class="app-sidebar__block app-sidebar__block--separate app-sidebar__block--semi-padding">
            <ul>
                <li>
                    <VAppSidebarButton :counter="tasksCounter" :route="{ name: 'user.tasks' }">
                        <template v-slot:icon>
                            <SidebarMyTasksSvg></SidebarMyTasksSvg>
                        </template>

                        {{ t('my-tasks') }}
                    </VAppSidebarButton>
                </li>

                <li>
                    <VAppSidebarButton :counter="goalsCounter" :route="{ name: 'user.goals' }">
                        <template v-slot:icon>
                            <SidebarMyGoalsSvg></SidebarMyGoalsSvg>
                        </template>

                        {{ t('my-goals') }}
                    </VAppSidebarButton>
                </li>

                <li>
                    <VAppSidebarButton :counter="approvementCounter" :route="{ name: 'user.approvements' }">
                        <template v-slot:icon>
                            <SidebarConfirmationSvg></SidebarConfirmationSvg>
                        </template>

                        {{ t('approvements') }}
                    </VAppSidebarButton>
                </li>

                <li>
                    <VAppSidebarButton
                        attention
                        :counter="notificationsCounter"
                        :route="{ name: 'user.notifications' }"
                    >
                        <template v-slot:icon>
                            <SidebarNotificationsSvg></SidebarNotificationsSvg>
                        </template>

                        {{ t('notifications') }}
                    </VAppSidebarButton>
                </li>

                <li v-if="canReadStatistic">
                    <VAppSidebarButton :route="{ name: 'statistic' }">
                        <template v-slot:icon>
                            <SidebarStatisticsSvg></SidebarStatisticsSvg>
                        </template>

                        {{ t('statistic') }}
                    </VAppSidebarButton>
                </li>
            </ul>
        </nav>

        <div class="app-sidebar__separator"></div>

        <div
            class="app-sidebar__block app-sidebar__block--scroll app-sidebar__block--separate-with-padding app-sidebar__block--padding"
        >
            <VAppSidebarExplorer></VAppSidebarExplorer>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
import TimesSvg from '../assets/times.svg';
import SidebarBrandSvg from '../assets/sidebar-brand.svg';
import SidebarBrandWinterSvg from '../assets/sidebar-brand-winter.svg';
import SidebarMyTasksSvg from '../assets/sidebar-my-tasks.svg';
import SidebarStatisticsSvg from '../assets/sidebar-statistic.svg';
import SidebarMyGoalsSvg from '../assets/sidebar-my-goals.svg';
import SidebarNotificationsSvg from '../assets/sidebar-notifications.svg';
import SidebarConfirmationSvg from '../assets/sidebar-confirmation.svg';

// Components
import VButton from './VButton.vue';
import VAppSidebarButton from './VAppSidebarButton.vue';
import VAppSidebarExplorer from './VAppSidebarExplorer.vue';

// Other
import context from '@/core/Mutators/NotificationCounter';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import Operations from '@/core/Authorization/Operations';
import ITask from '@/core/Models/ITask';
import MutationType from '@/core/Mutations/MutationType';
import ObjectType from '@/core/Mutations/ObjectType';
import TaskService from '@/core/Services/TaskService';
import TaskType from '@/core/Values/TaskType';
import { $error } from '@/utils/app-utils';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { isWinter } from '@/utils/utils';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

context.initialize();

let opened = ref(!!route.query.sidebar);
let isLocked = ref(false);

const winterMode = computed(Storages.Settings.computed<boolean>(Settings.UI.WinterEnabled, isWinter()));

const canReadStatistic = AuthorizationProvider.authorize(null, Operations.ReadStatistic);
const notificationsCounter = context.counter;

function closeSidebar() {
    if (!opened.value) {
        return;
    }

    router.replace({ query: { ...route.query, sidebar: undefined } });
}

async function createEmptyGoal() {
    isLocked.value = true;

    try {
        const mutations = await TaskService.createAsync(TaskType.Goal);

        const mutation = mutations.find(
            (mutation) => mutation.type === MutationType.Created && mutation.objectType === ObjectType.Task,
        );

        if (!mutation) {
            return;
        }

        router.push({ name: 'goals.view', params: { goalId: (mutation.objectState as ITask).id } });
    } catch (error) {
        $error(error);
    } finally {
        isLocked.value = false;
    }
}

async function createEmptyTask() {
    isLocked.value = true;

    try {
        const mutations = await TaskService.createAsync(TaskType.Task);

        const mutation = mutations.find(
            (mutation) => mutation.type === MutationType.Created && mutation.objectType === ObjectType.Task,
        );

        if (!mutation) {
            return;
        }

        router.replace({ name: 'user.tasks', query: { task: (mutation.objectState as ITask).id } });
    } catch (error) {
        $error(error);
    } finally {
        isLocked.value = false;
    }
}

const goalsCounter = computed((): number => 0);
const tasksCounter = computed((): number => 0);
const canCreateTasks = computed((): boolean => AuthorizationProvider.authorize({}, Operations.CreateTask));
const approvementCounter = computed((): number => 0);

watch(route, () => (opened.value = !!route.query.sidebar));
</script>

<style lang="scss">
.app-sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    --background-color: var(--background-primary);
    z-index: 101;

    @media (min-width: #{$breakpoint-tablet + 1}) {
        position: static;
    }

    &__block {
        display: flex;
        flex-direction: column;
        width: 100%;

        &--brand {
            position: relative;
            height: 2.25rem;
        }

        &--gap {
            gap: 0.5rem;
        }

        &--scroll {
            overflow: auto;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &--semi-gap {
            gap: 0.25rem;
        }

        &--padding {
            padding: 0 1rem;
        }

        &--separate {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }

        &--separate-top {
            margin-top: 1.5rem;
        }

        &--separate-with-padding {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        &--semi-padding {
            padding: 0 0.5rem;
        }
    }

    &__separator {
        width: calc(100% - 2rem);
        height: 0.0625rem;
        margin: 0 1rem;
        border-radius: 0.0625rem;
        background: var(--background-color);
        --background-color: var(--background-tertiary);
    }

    &__close-button {
        position: absolute;
        z-index: 102;
        top: -0.75rem;
        right: 1rem;
        padding: 0.5rem;

        color: var(--text-black-primary);

        @media (min-width: #{$breakpoint-tablet + 1}) {
            display: none;
        }
    }
}
</style>
