import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filter-popup__header" }

import TimesSvg from '@/assets/times.svg';
import SettingsSvg from '@/assets/settings.svg';

// Components
import VPopper from 'vue3-popper';
import VButton from './VButton.vue';
import VGridRow from './VGridRow.vue';
import VGridColumn from './VGridColumn.vue';
import VGridContainer from './VGridContainer.vue';

// Other
import { useI18n } from 'vue-i18n';
import { PropType, ref, Ref, watch, nextTick } from 'vue';
import IPageSettings, { IPageSettingsOption } from '@/core/Values/IPageSettings';

type LocalOption = { option: IPageSettingsOption; modelValue: Ref<unknown> };


export default /*@__PURE__*/_defineComponent({
  __name: 'VPageSettings',
  props: {
    options: {
        type: Array as PropType<IPageSettingsOption[]>,
        default: () => [],
    },
    modelValue: {
        type: Object as PropType<Partial<IPageSettings>>,
        required: true,
    },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const props = __props;
const emit = __emit;

let localModelValue = ref(props.modelValue);
let localOptions = ref(mapOptions(props.options));

watch(
    () => props.options,
    () => (localOptions.value = mapOptions(props.options)),
);

function mapOptions(options: IPageSettingsOption[]): LocalOption[] {
    return options.map((option) => ({ option, modelValue: ref<unknown>(option.read(localModelValue.value)) }));
}

function onUpdate(option: LocalOption) {
    // nextTick is required to modelValue was correctly updated in some of cases.
    nextTick(() => {
        option.option.save(localModelValue.value, option.modelValue);
        emit('update:modelValue', localModelValue.value);
    });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VPopper), _mergeProps({
    class: "page-settings",
    "offset-distance": "6",
    placement: "bottom-start",
    "z-index": "10000"
  }, _ctx.$attrs), {
    content: _withCtx(({ close }) => [
      _createVNode(VGridContainer, { class: "filter-popup" }, {
        default: _withCtx(() => [
          _createVNode(VGridRow, { class: "grid--space-between" }, {
            default: _withCtx(() => [
              _createVNode(VGridColumn, { class: "grid--center" }, {
                default: _withCtx(() => [
                  _createElementVNode("h5", _hoisted_1, _toDisplayString(_unref(t)('settings')), 1)
                ]),
                _: 1
              }),
              _createVNode(VGridColumn, null, {
                default: _withCtx(() => [
                  _createVNode(VGridRow, null, {
                    default: _withCtx(() => [
                      _createVNode(VButton, {
                        class: "button--rounded button--transparent button--vcenter button--margin-left",
                        onClick: close
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(TimesSvg))
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024),
          _createVNode(VGridRow, null, {
            default: _withCtx(() => [
              _createVNode(VGridContainer, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(localOptions), (option, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (option.option.title)
                        ? (_openBlock(), _createBlock(VGridRow, {
                            key: 0,
                            class: "grid--padding-top-100"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(option.option.title) + ": ", 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      _createVNode(VGridRow, {
                        class: _normalizeClass({
                                    'grid--padding-top-25': option.option.title,
                                    'grid--padding-top-100': !option.option.title,
                                })
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(option.option.component), _mergeProps({ ref_for: true }, option.option.attrs, {
                            modelValue: option.modelValue,
                            "onUpdate:modelValue": ($event: any) => ((option.modelValue) = $event)
                          }, _toHandlers({ [option.option.changeTrigger ?? 'update:modelValue']: () => onUpdate(option as LocalOption) })), null, 16, ["modelValue", "onUpdate:modelValue"]))
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ], 64))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 2
      }, 1024)
    ]),
    default: _withCtx(() => [
      _createVNode(VButton, { class: "button--secondary button--default button--center button--vcenter" }, {
        default: _withCtx(() => [
          _createVNode(_unref(SettingsSvg)),
          _createTextVNode(" " + _toDisplayString(_unref(t)('settings')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16))
}
}

})