<template>
    <div class="project-navigator">
        <div>
            <RouterLink
                class="project-navigator__project project-navigator__project--toggle"
                tabindex="0"
                :to="{
                    name: 'project.view',
                    params: {
                        projectId: project.id,
                    },
                }"
            >
                <span
                    class="project-navigator__project-icon"
                    :style="{
                        'background-color': '#80c342',
                    }"
                ></span>

                <h6 class="project-navigator__project-title">
                    {{ $tryTranslate(project.title) }}
                </h6>

                <VButton class="button--transparent project-navigator__project-options" v-if="false">
                    <DotsSvg></DotsSvg>
                </VButton>
            </RouterLink>

            <ul class="project-navigator__board-list" v-show="boardsVisible">
                <template v-for="board in sortedProjectBoards" :key="board.id">
                    <li class="project-navigator__board-item">
                        <RouterLink
                            class="project-navigator__board"
                            :to="{
                                name: 'projects.board',
                                params: {
                                    projectId: project.id,
                                    boardId: board.id,
                                },
                            }"
                        >
                            {{ $tryTranslate(board.title) }}
                        </RouterLink>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';

// Components
import VButton from './VButton.vue';

// Other
import IProject from '../core/Models/IProject';
import IBoard from '@/core/Models/IBoard';
import store from '@/store';
import orderBy from 'lodash.orderby';
import { computed, PropType, defineProps } from 'vue';
import { tryTranslate } from '../plugins/VueI18n';
import { useRoute } from 'vue-router';

const route = useRoute();
const props = defineProps({
    project: { type: Object as PropType<IProject>, required: true },
});

const boards = computed(
    (): IBoard[] => store.state.boards?.filter((board) => board.projectId === props.project.id) ?? [],
);
const boardsVisible = computed(() => props.project.id === parseInt(route.params.projectId?.toString(), 10));
const sortedProjectBoards = computed((): IBoard[] =>
    orderBy(boards.value, (board) => [100000 - board.type, board.order, tryTranslate(board.title)]),
);
</script>

<style lang="scss">
.project-navigator {
    &__project {
        display: flex;
        gap: 0.25rem;
        padding: 0.25rem 0.625rem 0.25rem 0.375rem;

        &--toggle {
            cursor: pointer;
        }
    }

    &__project-icon {
        flex-shrink: 1;
        width: 0.875rem;
        height: 0.875rem;
        margin: 0.3125rem;

        border-radius: 0.3125rem;
        background-color: var(--background-color);
        --background-color: attr(x-color);
    }

    &__project-title {
        margin: 0;
        flex-grow: 1;

        @include h6();
        @include overflow-ellipsis();
        line-height: 1.5rem; // needed
    }

    &__project-options {
        & svg {
            color: var(--text-black-secondary);
        }
    }

    &__board-item {
        display: flex;
        margin-top: 0.125rem;
        margin-left: 1.5625rem;
    }

    &__board-list {
        position: relative;
        margin-top: 0.125rem;

        &::after {
            content: ' ';
            position: absolute;
            width: 0.0625rem;
            top: 0.5rem;
            left: 0;
            bottom: 0.5rem;
            margin: 0 0.75rem;

            border-radius: 0.0625rem;
            background: var(--background-color);
            --background-color: var(--background-tertiary);
        }
    }

    &__board {
        display: inline-block;
        width: 100%;
        padding: 0.25rem 0rem 0.25rem 1rem;

        @include h7();
        @include overflow-ellipsis();
        line-height: 1.25rem;
        color: var(--text-black-primary);

        text-decoration: none;
    }

    &__board,
    &__project {
        color: var(--text-black-primary);
        border-radius: 0.5rem;

        &.router-link-exact-active,
        &:hover,
        &:focus-visible {
            background: var(--background-color);
            --background-color: var(--background-secondary);
        }

        &:active {
            background: var(--background-color);
            --background-color: none;
        }

        &:disabled {
            color: var(--text-black-tertiary);
        }
    }
}
</style>
