<template>
    <div class="search-field">
        <SearchSvg class="search-field__icon"></SearchSvg>

        <input
            v-model="value"
            aria-placeholder="search"
            class="input-field input-field--primary input-field--bordered search-field__input"
            :placeholder="t('search')"
            type="text"
        />
    </div>
</template>

<script lang="ts">
// Svg
import SearchSvg from '@/assets/search.svg';
import { debounce } from 'debounce';

// Components
//...

// Other
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

type DebounceFunction = ((value: string) => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    components: {
        SearchSvg,
    },

    props: {
        debounceMode: { type: Boolean, default: false },
        debounceInterval: { type: Number, default: 500 },
        modelValue: { type: String, required: true, default: '' },
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data: () => ({
        debounce: null as DebounceFunction | null,
    }),

    computed: {
        value: {
            get(): string {
                return this.modelValue;
            },

            set(value: string): void {
                if (!this.debounceMode) {
                    this.$emit('update:modelValue', value);
                    return;
                }

                if (!this.debounce) {
                    this.debounce = debounce((value: string) => {
                        this.debounce = null;
                        this.$emit('update:modelValue', value);
                    }, this.debounceInterval);
                }

                this.debounce(value);
            },
        },
    },
});
</script>

<style lang="scss">
.search-field {
    position: relative;
    height: 2rem;
    min-width: 6rem;

    &__icon {
        position: absolute;
        display: flex;
        width: 1rem;
        height: 1rem;
        top: 0.475rem;
        left: 0.75rem;
        bottom: 0;

        color: var(--text-black-primary);
    }

    &__input {
        height: 2rem;
        padding: 0.5rem 0.75rem 0.5rem 2rem;
        border-radius: 1.125rem;
    }
}
</style>
