import { defineProps, defineEmits } from 'vue';
<template>
    <span class="checkbox">
        <input :id="id" v-model="value" hidden type="checkbox" v-show="false" />

        <span class="checkbox__box" :class="{ 'checkbox__box--checked': modelValue }" @click="value = !value">
            <CheckSvg></CheckSvg>
        </span>

        <label class="checkbox__label" :for="id">{{ props.label }}</label>
    </span>
</template>

<script setup lang="ts">
// Svg
import CheckSvg from '@/assets/check.svg';

// Components
// ...

// Other
import { computed, defineEmits, defineProps } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    label: { type: String, default: '' },
    modelValue: { type: Boolean, default: false },
});

const id = 'checkbox-' + Math.random().toString(16).slice(2);
const value = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});
</script>

<style lang="scss">
.checkbox {
    display: flex;
    align-items: flex-end;
    height: 1.125rem;
    cursor: pointer;

    &__box {
        display: inline-block;
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 0.25rem;
        border: 0.0625rem var(--text-black-quaternary) solid;

        & svg {
            display: none;
            color: var(--text-white-primary);
        }

        &--checked {
            background-color: var(--brand-green);
            border-color: transparent;

            & svg {
                display: inline;
            }
        }
    }

    &__label {
        margin-left: 0.375rem;
    }
}
</style>
