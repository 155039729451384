import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["task-id"]
const _hoisted_2 = { class: "task-card-inline__row task-card-inline__row--status-padding" }
const _hoisted_3 = { class: "task-card-inline__row task-card-inline__row--horizontal task-card-inline__row--gap" }
const _hoisted_4 = {
  key: 0,
  class: "task-card-inline__hint"
}
const _hoisted_5 = {
  key: 1,
  class: "task-card-inline__hint"
}
const _hoisted_6 = {
  key: 2,
  class: "task-card-inline__hint"
}
const _hoisted_7 = {
  key: 3,
  class: "task-card-inline__hint"
}
const _hoisted_8 = {
  key: 0,
  class: "task-card-inline__counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VTaskStatus = _resolveComponent("VTaskStatus")!
  const _component_TextSvg = _resolveComponent("TextSvg")!
  const _component_EmailSvg = _resolveComponent("EmailSvg")!
  const _component_AttachmentSvg = _resolveComponent("AttachmentSvg")!
  const _component_VUserCard = _resolveComponent("VUserCard")!
  const _component_DotsSvg = _resolveComponent("DotsSvg")!
  const _component_VButtonDropdown = _resolveComponent("VButtonDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["task-card-inline prevent-close", {
            'task-card-inline--expired': _ctx.isExpired,
            'task-card-inline--finished': _ctx.isFinished,
            'task-card-inline--selected': _ctx.isSelected,
        }]),
    "task-id": _ctx.task.id,
    onContextmenu: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openContextMenu($event)))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VTaskStatus, {
        "show-title": false,
        task: _ctx.task
      }, null, 8, ["task"])
    ]),
    _createElementVNode("p", {
      class: _normalizeClass(["task-card-inline__row task-card-inline__title", {
                'task-card-inline__title--blurry': !_ctx.task.title || _ctx.isExpired || _ctx.isFinished,
            }])
    }, _toDisplayString(_ctx.task.title ? _ctx.task.title : _ctx.t('untitled')), 3),
    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "task-card-inline__row task-card-inline__row--void" }, null, -1)),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.hasDescription)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_TextSvg)
          ]))
        : _createCommentVNode("", true),
      (_ctx.numberOfComments > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_EmailSvg),
            _createElementVNode("span", null, _toDisplayString(_ctx.numberOfComments), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.numberOfAttachments > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_AttachmentSvg)
          ]))
        : _createCommentVNode("", true),
      (_ctx.contributors.length > 0 && _ctx.contributors[0].user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_VUserCard, {
              "picture-only": "",
              tiny: "",
              user: _ctx.contributors[0].user
            }, null, 8, ["user"]),
            (_ctx.contributors.length > 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, " +" + _toDisplayString(_ctx.contributors.length - 1), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.actions?.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: "task-card-inline__hint",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"]))
          }, [
            _createVNode(_component_VButtonDropdown, {
              options: _ctx.actions,
              placement: "left-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DotsSvg)
              ]),
              _: 1
            }, 8, ["options"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 42, _hoisted_1))
}