import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "goal-page-tools" }
const _hoisted_2 = {
  key: 0,
  class: "goal-page__content goal-page__content--center"
}
const _hoisted_3 = {
  key: 1,
  class: "goal-page__content goal-page__content--center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BellSvg = _resolveComponent("BellSvg")!
  const _component_VButton = _resolveComponent("VButton")!
  const _component_VPageHeader = _resolveComponent("VPageHeader")!
  const _component_VLoader = _resolveComponent("VLoader")!
  const _component_NoDataSvg = _resolveComponent("NoDataSvg")!
  const _component_NoDataDarkSvg = _resolveComponent("NoDataDarkSvg")!
  const _component_VIllustration = _resolveComponent("VIllustration")!
  const _component_VGoalPanel = _resolveComponent("VGoalPanel")!
  const _component_VPageContent = _resolveComponent("VPageContent")!
  const _component_VPage = _resolveComponent("VPage")!

  return (_openBlock(), _createBlock(_component_VPage, { class: "app-page--fullscreen" }, {
    default: _withCtx(() => [
      _createVNode(_component_VPageHeader, {
        "show-third-block": "",
        title: _ctx.pageTitle
      }, {
        second: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_VButton, {
              class: _normalizeClass(["button--primary button--default button--vcenter", { active: _ctx.showStories }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showStories = !_ctx.showStories))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BellSvg),
                _createTextVNode(" " + _toDisplayString(_ctx.t('feed-story-title')), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_VPageContent, {
        class: "goal-page",
        vertical: ""
      }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_VLoader, { class: "loader--mini loader--center" })
              ]))
            : (!_ctx.goal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_VIllustration, {
                    tip: _ctx.t('goal-not-found')
                  }, {
                    default: _withCtx(() => [
                      (_ctx.theme === 'light')
                        ? (_openBlock(), _createBlock(_component_NoDataSvg, { key: 0 }))
                        : (_ctx.theme === 'dark')
                          ? (_openBlock(), _createBlock(_component_NoDataDarkSvg, { key: 1 }))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["tip"])
                ]))
              : (_openBlock(), _createBlock(_component_VGoalPanel, {
                  key: 2,
                  "show-stories": _ctx.showStories,
                  "onUpdate:showStories": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showStories) = $event)),
                  board: _ctx.board,
                  goal: _ctx.goal,
                  project: _ctx.project,
                  tasks: _ctx.tasks,
                  uploader: _ctx.uploader,
                  onCreateEmptyTask: _ctx.createEmptyTask
                }, null, 8, ["show-stories", "board", "goal", "project", "tasks", "uploader", "onCreateEmptyTask"]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}