import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropzone__area" }
const _hoisted_2 = { class: "dropzone__text" }

import DataTransferExtractor from '@/core/DataTransferExtractor';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import { $error } from '@/utils/app-utils';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VDropzone',
  props: {
    message: { type: String, default: '' },
},
  emits: ['drop'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const emit = __emit;
const props = __props;

let show = ref(false);
let dropzone = ref(null as HTMLDivElement | null);

function showDropzone() {
    show.value = true;
}

function hideDropzone() {
    show.value = false;
}

function dropHandler(event: DragEvent) {
    if (!event.dataTransfer) {
        return;
    }

    try {
        const files = DataTransferExtractor.extract(event.dataTransfer);
        emit('drop', files);
    } catch (error) {
        $error(error);
    }
}

onMounted(() => {
    if (dropzone.value) {
        dropzone.value.addEventListener('drop', dropHandler, false);
    }

    emitter.on(EventNames.FileEnterWindow, showDropzone);
    emitter.on(EventNames.FileLeaveWindow, hideDropzone);
});

onBeforeMount(() => {
    if (dropzone.value) {
        dropzone.value.removeEventListener('drop', dropHandler, false);
    }

    emitter.off(EventNames.FileEnterWindow, showDropzone);
    emitter.off(EventNames.FileLeaveWindow, hideDropzone);
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref_key: "dropzone",
    ref: dropzone,
    class: "dropzone"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(props.message || _unref(t)('attach-files')), 1)
    ])
  ], 512)), [
    [_vShow, _unref(show)]
  ])
}
}

})