export class EventNames {
    public static Error = 'application.error';
    public static Alert = 'application.alert';
    public static Confirm = 'application.confirm';
    public static ShowImage = 'application.show-image';
    public static LogError = 'application.log-error';
    public static TaskPanelClosed = 'task-panel:closed';
    public static TaskPanelBeforeOpen = 'task-panel:before-open';
    public static TaskPanelOpened = 'task-panel:opened';
    public static DbObjectsMutated = 'db.mutation';
    public static NotificationRead = 'notifications.read';
    public static NotificationCreated = 'notifications.created';
    public static NotificationDeleted = 'notifications.deleted';
    public static ConnectionLoopClosed = 'connection-loop.closed';
    public static ConnectionLoopReconnected = 'connection-loop.reconnected';
    public static FileEnterWindow = 'application.file-enter-window';
    public static FileLeaveWindow = 'application.file-leave-window';
}
