import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "app-sidebar" }
const _hoisted_2 = { class: "app-sidebar__block app-sidebar__block--brand app-sidebar__block--separate-top" }
const _hoisted_3 = {
  key: 0,
  class: "app-sidebar__block app-sidebar__block--gap app-sidebar__block--padding app-sidebar__block--separate-top"
}
const _hoisted_4 = { class: "app-sidebar__block app-sidebar__block--separate app-sidebar__block--semi-padding" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "app-sidebar__block app-sidebar__block--scroll app-sidebar__block--separate-with-padding app-sidebar__block--padding" }

import TimesSvg from '../assets/times.svg';
import SidebarBrandSvg from '../assets/sidebar-brand.svg';
import SidebarBrandWinterSvg from '../assets/sidebar-brand-winter.svg';
import SidebarMyTasksSvg from '../assets/sidebar-my-tasks.svg';
import SidebarStatisticsSvg from '../assets/sidebar-statistic.svg';
import SidebarMyGoalsSvg from '../assets/sidebar-my-goals.svg';
import SidebarNotificationsSvg from '../assets/sidebar-notifications.svg';
import SidebarConfirmationSvg from '../assets/sidebar-confirmation.svg';

// Components
import VButton from './VButton.vue';
import VAppSidebarButton from './VAppSidebarButton.vue';
import VAppSidebarExplorer from './VAppSidebarExplorer.vue';

// Other
import context from '@/core/Mutators/NotificationCounter';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import Operations from '@/core/Authorization/Operations';
import ITask from '@/core/Models/ITask';
import MutationType from '@/core/Mutations/MutationType';
import ObjectType from '@/core/Mutations/ObjectType';
import TaskService from '@/core/Services/TaskService';
import TaskType from '@/core/Values/TaskType';
import { $error } from '@/utils/app-utils';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { isWinter } from '@/utils/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'VAppSidebar',
  setup(__props) {

// Svg
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

context.initialize();

let opened = ref(!!route.query.sidebar);
let isLocked = ref(false);

const winterMode = computed(Storages.Settings.computed<boolean>(Settings.UI.WinterEnabled, isWinter()));

const canReadStatistic = AuthorizationProvider.authorize(null, Operations.ReadStatistic);
const notificationsCounter = context.counter;

function closeSidebar() {
    if (!opened.value) {
        return;
    }

    router.replace({ query: { ...route.query, sidebar: undefined } });
}

async function createEmptyGoal() {
    isLocked.value = true;

    try {
        const mutations = await TaskService.createAsync(TaskType.Goal);

        const mutation = mutations.find(
            (mutation) => mutation.type === MutationType.Created && mutation.objectType === ObjectType.Task,
        );

        if (!mutation) {
            return;
        }

        router.push({ name: 'goals.view', params: { goalId: (mutation.objectState as ITask).id } });
    } catch (error) {
        $error(error);
    } finally {
        isLocked.value = false;
    }
}

async function createEmptyTask() {
    isLocked.value = true;

    try {
        const mutations = await TaskService.createAsync(TaskType.Task);

        const mutation = mutations.find(
            (mutation) => mutation.type === MutationType.Created && mutation.objectType === ObjectType.Task,
        );

        if (!mutation) {
            return;
        }

        router.replace({ name: 'user.tasks', query: { task: (mutation.objectState as ITask).id } });
    } catch (error) {
        $error(error);
    } finally {
        isLocked.value = false;
    }
}

const goalsCounter = computed((): number => 0);
const tasksCounter = computed((): number => 0);
const canCreateTasks = computed((): boolean => AuthorizationProvider.authorize({}, Operations.CreateTask));
const approvementCounter = computed((): number => 0);

watch(route, () => (opened.value = !!route.query.sidebar));

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!winterMode.value)
        ? (_openBlock(), _createBlock(_unref(SidebarBrandSvg), { key: 0 }))
        : (_openBlock(), _createBlock(_unref(SidebarBrandWinterSvg), { key: 1 })),
      _createVNode(VButton, {
        class: "button--rounded button--transparent app-sidebar__close-button",
        onClick: closeSidebar
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TimesSvg))
        ]),
        _: 1
      })
    ]),
    (canCreateTasks.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(VButton, {
            class: "button button--primary button--default button--center button--fluid",
            disabled: _unref(isLocked),
            onClick: createEmptyGoal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('create-goal')), 1)
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(VButton, {
            class: "button button--primary button--default button--center button--fluid",
            disabled: _unref(isLocked),
            onClick: createEmptyTask
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('create-task')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("nav", _hoisted_4, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(VAppSidebarButton, {
            counter: tasksCounter.value,
            route: { name: 'user.tasks' }
          }, {
            icon: _withCtx(() => [
              _createVNode(_unref(SidebarMyTasksSvg))
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_unref(t)('my-tasks')), 1)
            ]),
            _: 1
          }, 8, ["counter"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(VAppSidebarButton, {
            counter: goalsCounter.value,
            route: { name: 'user.goals' }
          }, {
            icon: _withCtx(() => [
              _createVNode(_unref(SidebarMyGoalsSvg))
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_unref(t)('my-goals')), 1)
            ]),
            _: 1
          }, 8, ["counter"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(VAppSidebarButton, {
            counter: approvementCounter.value,
            route: { name: 'user.approvements' }
          }, {
            icon: _withCtx(() => [
              _createVNode(_unref(SidebarConfirmationSvg))
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_unref(t)('approvements')), 1)
            ]),
            _: 1
          }, 8, ["counter"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(VAppSidebarButton, {
            attention: "",
            counter: _unref(notificationsCounter),
            route: { name: 'user.notifications' }
          }, {
            icon: _withCtx(() => [
              _createVNode(_unref(SidebarNotificationsSvg))
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_unref(t)('notifications')), 1)
            ]),
            _: 1
          }, 8, ["counter"])
        ]),
        (_unref(canReadStatistic))
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createVNode(VAppSidebarButton, { route: { name: 'statistic' } }, {
                icon: _withCtx(() => [
                  _createVNode(_unref(SidebarStatisticsSvg))
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_unref(t)('statistic')), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "app-sidebar__separator" }, null, -1)),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(VAppSidebarExplorer)
    ])
  ], 512)), [
    [_vShow, _unref(opened) || !_ctx.$device.isMobile]
  ])
}
}

})