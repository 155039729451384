<template>
    <VModal v-model:visible="visible" class="v-modal--error" @close="close">
        <h2 class="v-modal__row v-modal__row--header v-modal__title">
            {{ context?.title }}
        </h2>

        <p class="v-modal__row v-modal__row--content v-modal__content">
            {{ context?.message }}
        </p>

        <template v-if="context?.withComment">
            <VContentEditable
                v-model="comment"
                v-focus
                class="simple-contenteditable--great simple-contenteditable--bordered"
                :class="{ error: commentErrorMessage }"
                :maxlength="context.commentMaxLength"
                :placeholder="t('your-comment')"
            ></VContentEditable>
            <small class="v-modal__hint">{{ context.commentHint }}</small>
            <small class="error-message">{{ commentErrorMessage }}</small>
        </template>

        <div class="v-modal__row v-modal__row--actions">
            <VButton
                class="button--rectangle button--center button--blurry v-modal__action"
                :class="context?.buttonCancelClasses"
                @click="close"
            >
                {{ context?.buttonCancelName }}</VButton
            >

            <VButton
                class="button--rectangle button--center button--negative v-modal__action"
                :class="context?.buttonOkClasses"
                @click="ok"
            >
                {{ context?.buttonOkName }}
            </VButton>
        </div>
    </VModal>
</template>

<script setup lang="ts">
// Svg
// ...

// Components
import VModal from './VModal.vue';
import VButton from './VButton.vue';

// Other
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import ConfirmContext, { ConfirmOpenEvent } from '@/core/Values/ConfirmContext';
import VContentEditable from './VContentEditable.vue';
import { debounce } from 'debounce';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

type DebounceFunction = (() => void) & { clear(): void } & { flush(): void };

const { t } = useI18n();

let comment = ref('' as string | undefined);
let commentErrorMessage = ref(null as string | null);
let commentErrorDebounce = ref(null as DebounceFunction | null);
let visible = ref(false);
let context = ref(null as ConfirmContext | null);

emitter.on(EventNames.Confirm, open);

function open(event: ConfirmOpenEvent) {
    comment.value = '';
    visible.value = true;
    context.value = {
        title: t('confirm-title'),
        buttonOkName: t('ok'),
        buttonCancelName: t('cancel'),
        ...event,
    };
}

function ok() {
    if (context.value?.commentRequired && !comment.value) {
        commentErrorMessage.value = t('field-required');

        if (!commentErrorDebounce.value) {
            commentErrorDebounce.value = debounce(
                () => (commentErrorMessage.value = commentErrorDebounce.value = null),
                3000,
            ); // 3s
        }

        if (commentErrorDebounce.value) {
            commentErrorDebounce.value();
        }

        return;
    }

    if (context.value?.okCallback) {
        context.value.okCallback(comment.value);
    }

    context.value = null;
    visible.value = false;
}

function close() {
    commentErrorDebounce.value?.clear();
    commentErrorDebounce.value = null;
    commentErrorMessage.value = null;

    if (context.value?.cancelCallback) {
        context.value.cancelCallback(comment.value);
    }

    context.value = null;
    visible.value = false;
}
</script>

<style lang="scss">
.v-modal {
    &--error {
        max-width: 32rem;
    }
}
</style>
