import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["counter"]
const _hoisted_2 = { class: "filter-popup__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterSvg = _resolveComponent("FilterSvg")!
  const _component_VButton = _resolveComponent("VButton")!
  const _component_VGridColumn = _resolveComponent("VGridColumn")!
  const _component_TimesSvg = _resolveComponent("TimesSvg")!
  const _component_VGridRow = _resolveComponent("VGridRow")!
  const _component_VCheckbox = _resolveComponent("VCheckbox")!
  const _component_VGridContainer = _resolveComponent("VGridContainer")!
  const _component_PlusSvg = _resolveComponent("PlusSvg")!
  const _component_VButtonDropdown = _resolveComponent("VButtonDropdown")!
  const _component_VPopper = _resolveComponent("VPopper")!

  return (_openBlock(), _createBlock(_component_VPopper, _mergeProps({
    class: "task-filter",
    "offset-distance": "6",
    placement: "bottom-start",
    "z-index": "10000"
  }, _ctx.$attrs), {
    content: _withCtx(({ close }) => [
      _createVNode(_component_VGridContainer, { class: "filter-popup" }, {
        default: _withCtx(() => [
          _createVNode(_component_VGridRow, { class: "grid--space-between" }, {
            default: _withCtx(() => [
              _createVNode(_component_VGridColumn, { class: "grid--center" }, {
                default: _withCtx(() => [
                  _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.t('filters')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_VGridColumn, null, {
                default: _withCtx(() => [
                  _createVNode(_component_VGridRow, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_VButton, {
                        class: "button--link button--vcenter button--margin-right",
                        onClick: _ctx.clear
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('clear')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_VButton, {
                        class: "button--rounded button--transparent button--vcenter button--margin-left",
                        onClick: close
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TimesSvg)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_VGridRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_VGridContainer, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      _createVNode(_component_VGridRow, { class: "grid--padding-top-100" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(filter.title) + ": ", 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_VGridRow, { class: "grid--padding-top-25" }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(filter.component), _mergeProps({ ref_for: true }, filter.attrs, {
                            modelValue: filter.modelValue,
                            "onUpdate:modelValue": ($event: any) => ((filter.modelValue) = $event)
                          }, _toHandlers(filter.on)), null, 16, ["modelValue", "onUpdate:modelValue"]))
                        ]),
                        _: 2
                      }, 1024)
                    ], 64))
                  }), 128)),
                  _createVNode(_component_VGridRow, { class: "grid--padding-top-100" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_VCheckbox, {
                        modelValue: _ctx.showArchived,
                        "onUpdate:modelValue": [
                          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showArchived) = $event)),
                          _cache[1] || (_cache[1] = () => _ctx.updateModelValue(true))
                        ],
                        label: _ctx.t('show-archived')
                      }, null, 8, ["modelValue", "label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_component_VGridRow, { class: "grid--padding-top-100" }, {
            default: _withCtx(() => [
              _createVNode(_component_VButtonDropdown, {
                options: _ctx.displayFilterOptions,
                placement: "bottom-start"
              }, {
                button: _withCtx(() => [
                  _createVNode(_component_VButton, { class: "button--secondary button--transparent" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PlusSvg),
                      _createTextVNode(" " + _toDisplayString(_ctx.t('add-filter')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["options"])
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.displayFilterOptions.length]
          ])
        ]),
        _: 2
      }, 1024)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_VButton, {
        class: _normalizeClass(["button--secondary button--default button--center button--vcenter task-filter__control", { 'task-filter__control--active': _ctx.filtersCount > 0 }])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_FilterSvg),
          _createElementVNode("span", { counter: _ctx.filtersCount }, _toDisplayString(_ctx.t('filter')), 9, _hoisted_1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 16))
}