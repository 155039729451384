<template>
    <div
        class="task-card-inline prevent-close"
        :class="{
            'task-card-inline--expired': isExpired,
            'task-card-inline--finished': isFinished,
            'task-card-inline--selected': isSelected,
        }"
        :task-id="task.id"
        @contextmenu="openContextMenu($event)"
    >
        <div class="task-card-inline__row task-card-inline__row--status-padding">
            <VTaskStatus :show-title="false" :task="task"></VTaskStatus>
        </div>

        <p
            class="task-card-inline__row task-card-inline__title"
            :class="{
                'task-card-inline__title--blurry': !task.title || isExpired || isFinished,
            }"
        >
            {{ task.title ? task.title : t('untitled') }}
        </p>

        <span class="task-card-inline__row task-card-inline__row--void"></span>

        <div class="task-card-inline__row task-card-inline__row--horizontal task-card-inline__row--gap">
            <div class="task-card-inline__hint" v-if="hasDescription">
                <TextSvg></TextSvg>
            </div>

            <div class="task-card-inline__hint" v-if="numberOfComments > 0">
                <EmailSvg></EmailSvg>

                <span>
                    {{ numberOfComments }}
                </span>
            </div>

            <div class="task-card-inline__hint" v-if="numberOfAttachments > 0">
                <AttachmentSvg></AttachmentSvg>
            </div>

            <div class="task-card-inline__hint" v-if="contributors.length > 0 && contributors[0].user">
                <VUserCard picture-only tiny :user="contributors[0].user"></VUserCard>

                <span class="task-card-inline__counter" v-if="contributors.length > 1">
                    +{{ contributors.length - 1 }}
                </span>
            </div>

            <div class="task-card-inline__hint" @click.stop.prevent v-if="actions?.length">
                <VButtonDropdown :options="actions" placement="left-start">
                    <DotsSvg></DotsSvg>
                </VButtonDropdown>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';
import TextSvg from '@/assets/text.svg';
import EmailSvg from '@/assets/email.svg';
import AttachmentSvg from '@/assets/attachment.svg';

// Components
import VTaskStatus from './VTaskStatus.vue';
import VUserCard from './VUserCard.vue';
import VButtonDropdown from './VButtonDropdown.vue';

// Other
import ITask from '@/core/Models/ITask';
import StoryType from '@/core/Values/StoryType';
import { formatDateOnly } from '@/utils/date-utils';
import { DateTime } from 'luxon';
import Status from '@/core/Values/Status';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import ICollaborator from '@/core/Models/ICollaborator';
import IUser from '@/core/Models/IUser';
import store from '@/store';
import { defineComponent, ref } from 'vue';
import { PropType } from 'vue';
import { useTaskActions } from '@/mixins/TaskApi';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default defineComponent({
    components: {
        DotsSvg,
        TextSvg,
        EmailSvg,
        AttachmentSvg,

        VTaskStatus,
        VUserCard,
        VButtonDropdown,
    },

    setup(props) {
        const { t } = useI18n();
        const route = useRoute();
        const actions = useTaskActions(ref(props.task));

        return {
            t,
            route,
            ...actions,
        };
    },

    props: {
        task: { type: Object as PropType<ITask>, required: true },
    },

    computed: {
        taskOrGoal(): ITask {
            return this.task;
        },

        numberOfComments(): number {
            if (typeof this.task.commentsCount === 'number') {
                return this.task.commentsCount;
            }

            return this.task.stories?.filter((story) => story.type === StoryType.Comment)?.length ?? 0;
        },

        numberOfAttachments(): number {
            if (typeof this.task.attachmentsCount === 'number') {
                return this.task.attachmentsCount;
            }

            return this.task.stories?.filter((story) => story.type === StoryType.Attachment)?.length ?? 0;
        },

        hasDescription(): boolean {
            return !!this.task.description;
        },

        formattedDeadline(): string {
            if (!this.task?.deadline) {
                return '';
            }

            return formatDateOnly(this.task.deadline);
        },

        isExpired(): boolean {
            if (!this.task.deadline || this.task.status !== Status.InProgress) {
                return false;
            }

            return DateTime.fromISO(this.task.deadline, { zone: 'UTC' }) < DateTime.now();
        },

        isFinished(): boolean {
            return this.task.status === Status.Finished;
        },

        isCompleted(): boolean {
            return this.task.status === Status.Completed;
        },

        isSelected(): boolean {
            return this.task.id === parseInt(this.route.query.task as string, 10);
        },

        contributors(): ICollaborator[] {
            return (
                this.task.collaborators?.filter((collaborator) => collaborator.role === CollaboratorRole.Assignee) ?? []
            );
        },

        currentUser(): IUser | null {
            return store.state.user;
        },
    },
});
</script>

<style lang="scss">
.task-card-inline {
    display: flex;
    height: 2.125rem;
    width: 100%;
    padding: 0.25rem 0.75rem;
    border-top: 0.0625rem;
    border-left: 0;
    border-right: 0;
    border-bottom: 0.0625rem;
    border-style: solid;
    border-color: var(--background-tertiary);
    background: var(--background-color);
    --background-color: var(--background-primary);

    &--completed {
        text-decoration: line-through;
    }

    &--finished {
        text-decoration: line-through;
    }

    &:hover,
    &--selected {
        border-color: var(--color-blue);
        background: var(--background-color);
        --background-color: var(--background-blue);
    }

    &__row {
        &--void {
            min-width: 0.5rem;
            flex-grow: 1;
        }

        &--gap {
            gap: 0.25rem;
        }

        &--horizontal {
            display: flex;
        }

        &--status-padding {
            padding: 0 0.5rem 0 0;
        }
    }

    &__hint {
        display: flex;
        height: 1.5rem;
        line-height: 1.5rem;

        text-wrap: nowrap;
        color: var(--text-black-secondary);

        &--space-right {
            margin-right: auto;
        }
    }

    &__counter {
        margin-left: 0.25rem;
    }

    &__title {
        margin: 0;
        min-width: 0;
        text-decoration: inherit;

        @include h7();
        @include overflow-ellipsis();

        color: var(--text-black-primary);
        line-height: 1.5rem;

        &--blurry {
            color: var(--text-black-tertiary);
        }
    }
}
</style>
