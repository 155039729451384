import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-page app-page--no-shrink app-page--panel prevent-close" }
const _hoisted_2 = {
  key: 0,
  class: "home-task-panel__content home-task-panel__content--center"
}
const _hoisted_3 = {
  key: 1,
  class: "home-task-panel__content home-task-panel__content--center"
}

import NoDataSvg from '@/assets/no-data.svg';
import NoDataDarkSvg from '@/assets/no-data-dark.svg';

// Components
import VLoader from '../components/VLoader.vue';
import VTaskPanel from '../components/VTaskPanel.vue';
import VIllustration from '../components/VIllustration.vue';
import { Pane as VPane } from 'splitpanes';

// Other
import { pushPageTitle, pullPageTitle } from '@/utils/document-utils';
import ITask from '@/core/Models/ITask';
import { hasParentWithClassName, isClickOnScrollbar } from '@/utils/dom-utils';
import TaskService from '@/core/Services/TaskService';
import UserMapper from '@/core/UserMapper';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import TaskType from '@/core/Values/TaskType';
import { Raw, markRaw, ref, nextTick, onMounted, watch } from 'vue';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import MutationBus from '@/core/Mutations/MutationBus';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import { objectStorageUploader } from '@/core/Uploader/UploaderBuilder';
import { $error } from '@/utils/app-utils';

import store from '@/store';
import IBoard from '@/core/Models/IBoard';
import IProject from '@/core/Models/IProject';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { computed } from '@vue/reactivity';
import { onBeforeUnmount } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SharedTaskPanel',
  setup(__props) {

// Svg
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

let el = ref(null as HTMLDivElement | null);
let loading = ref(true);
let task = ref(null as ITask | null);
let parent = ref(null as ITask | null);
let subtasks = ref(null as ITask[] | null);
let uploader = objectStorageUploader;
let mutatorContexts: Raw<IMutatorContext[]> | null = null;

const panelWidth = computed(Storages.Settings.computedDebounce(Settings.UI.PanelWidth, 50));
const theme = computed(() => Storages.Settings.get(Settings.UI.Theme));
const taskId = computed(() => parseInt(route.query?.task as string) || 0);

const board = computed((): IBoard | undefined => {
    return store.state.boards?.find((board) => board.id === task.value?.boardId);
});

const project = computed((): IProject | undefined => {
    return store.state.projects?.find((project) => project.id === task.value?.projectId);
});

const visible = computed((): boolean => {
    const visible = !!taskId.value;
    return visible;
});

const canCreateSubTasks = computed((): boolean => {
    return task.value?.type === TaskType.Task;
});

const removeAfterEachListener = router.afterEach(triggerBeforeOpenHook);
emitter.on(EventNames.ConnectionLoopReconnected, fetchTask);

watch(() => taskId.value, onVisibleChanged);
watch(() => task.value?.parentId, onParentChanged, { deep: true });

onMounted(() => {
    if (visible.value) {
        beforeOpen();
    }

    document.addEventListener('mousedown', onDocumentClick);
});

onBeforeUnmount((): void => {
    // Deactivate old context, to avoid memory leaks.
    MutationBus.deactivate(mutatorContexts);

    if (removeAfterEachListener) {
        removeAfterEachListener();
    }

    document.removeEventListener('mousedown', onDocumentClick);
    emitter.off(EventNames.ConnectionLoopReconnected, fetchTask);
});

function close(force = false): void {
    router.replace({
        query: {
            ...route.query,
            prevTask: undefined,
            prevSelected: undefined,
            task: force ? undefined : route.query.prevTask,
            selected: force ? undefined : route.query.prevSelected,
        },
    });
}

async function fetchTask(): Promise<void> {
    loading.value = true;

    try {
        const result = await TaskService.queryAsync({
            whereId: [taskId.value],
            whereType: [TaskType.Task, TaskType.Subtask],
            withArchived: true,
            perPage: 1,
            includes: ['stories', 'approvements', 'collaborators', 'save-points-count'],
        });

        const item = result.length > 0 ? result[0] : null;

        if (!item) {
            return;
        }

        if (item.parentId) {
            await fetchParent(item.parentId);
        }

        task.value = item;
        subtasks.value = null;

        UserMapper.mapTaskAsync(task.value);
        ObjectStorageMapper.mapTaskAsync(task.value);

        // Creates the mutation context for watching for task.
        const contexts: IMutatorContext[] = [
            new TaskMutatorContext([task.value], {
                mapUsers: true,
                mapAttachments: true,
                ignoreTaskCreating: () => true,
            }),
        ];

        // Loads the subtasks if the task can contains the subtasks.
        if (task.value.type === TaskType.Task) {
            subtasks.value = await TaskService.queryAsync({
                whereTaskId: [taskId.value],
                whereType: [TaskType.Subtask],
                includes: ['comments-count', 'attachments-count', 'collaborators', 'approvements'],
            });

            UserMapper.mapTasksAsync(subtasks.value);

            contexts.push(
                new TaskMutatorContext(subtasks.value, {
                    mapUsers: true,
                    ignoreTaskCreating: (item) => item.parentId !== task.value?.id,
                    excludeTask: (item) => !!item.archivedAt,
                }),
            );
        }

        // Deactivate old context, to avoid memory leaks.
        MutationBus.deactivate(mutatorContexts);
        mutatorContexts = markRaw(contexts);
        MutationBus.activate(mutatorContexts);

        nextTick(() =>
            emitter.emit(EventNames.TaskPanelOpened, {
                task,
                taskId: task.value?.id,
                clientWidth: (el.value?.clientWidth ?? 0) + 2,
            }),
        );
    } catch (error) {
        $error(error);
    } finally {
        loading.value = false;
    }
}

async function fetchParent(parentId: number): Promise<void> {
    const tasks = await TaskService.queryAsync({
        whereId: [parentId],
        perPage: 1,
        withArchived: true,
    });

    parent.value = tasks.length > 0 ? tasks[0] : null;
}

async function onDocumentClick(event: MouseEvent): Promise<void> {
    if (!visible.value) {
        return;
    }

    const element = event.target as HTMLElement;

    let stopCloseEvent = isClickOnScrollbar(event);
    if (stopCloseEvent) {
        return;
    }

    stopCloseEvent = hasParentWithClassName(element, ['prevent-close', 'splitpanes__splitter']);
    if (stopCloseEvent) {
        return;
    }

    close(true);
}

function beforeOpen(): Promise<void> {
    pushPageTitle(t('task-number', { number: taskId.value }));

    triggerBeforeOpenHook();

    return fetchTask();
}

function beforeClose(): void {
    triggerBeforeClosedHook();

    task.value = null;
    parent.value = null;
    subtasks.value = null;

    // Deactivate old context, to avoid memory leaks.
    MutationBus.deactivate(mutatorContexts);
}

function onVisibleChanged(taskId: number, oldTaskId: number): void {
    pullPageTitle(t('task-number', { number: oldTaskId }));

    if (!taskId) {
        beforeClose();
    } else if (taskId != task.value?.id) {
        beforeOpen();
    }
}

function onParentChanged(newValue: number | undefined | null, oldValue: number | undefined | null): void {
    if (!task.value?.parentId) {
        parent.value = null;
    }

    if (newValue !== oldValue && task.value?.parentId && parent.value?.id !== newValue) {
        fetchParent(task.value?.parentId);
    }
}

function triggerBeforeOpenHook(): void {
    nextTick(() =>
        emitter.emit(EventNames.TaskPanelBeforeOpen, {
            taskId: taskId.value,
            clientWidth: (el.value?.clientWidth ?? 0) + 2,
        }),
    );
}

function triggerBeforeClosedHook() {
    emitter.emit(EventNames.TaskPanelClosed, { taskId: taskId.value });
}

return (_ctx: any,_cache: any) => {
  return (_unref(visible))
    ? (_openBlock(), _createBlock(_unref(VPane), {
        key: 0,
        ref_key: "el",
        ref: el,
        class: _normalizeClass(["splitpanes__pane--sidebar splitpanes__pane--shadow", {
            'splitpanes__pane--fixed': _ctx.$device.isMobile,
        }]),
        "max-size": "80",
        "min-size": "35",
        size: _ctx.$device.isMobile ? 100 : _unref(panelWidth)
      }, {
        default: _withCtx(() => [
          _createElementVNode("aside", _hoisted_1, [
            (_unref(loading))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(VLoader, { class: "loader--mini loader--center" })
                ]))
              : (!_unref(task))
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(VIllustration, {
                      tip: _unref(t)('task-not-found')
                    }, {
                      default: _withCtx(() => [
                        (_unref(theme) === 'light')
                          ? (_openBlock(), _createBlock(_unref(NoDataSvg), { key: 0 }))
                          : (_unref(theme) === 'dark')
                            ? (_openBlock(), _createBlock(_unref(NoDataDarkSvg), { key: 1 }))
                            : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["tip"])
                  ]))
                : (_openBlock(), _createBlock(VTaskPanel, {
                    key: 2,
                    board: _unref(board),
                    "can-create-sub-tasks": _unref(canCreateSubTasks),
                    parent: _unref(parent),
                    project: _unref(project),
                    subtasks: _unref(subtasks),
                    task: _unref(task),
                    uploader: _unref(uploader),
                    onClose: close
                  }, null, 8, ["board", "can-create-sub-tasks", "parent", "project", "subtasks", "task", "uploader"]))
          ])
        ]),
        _: 1
      }, 8, ["class", "size"]))
    : _createCommentVNode("", true)
}
}

})