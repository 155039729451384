import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "simple-contenteditable-wrapper" }
const _hoisted_2 = ["contenteditable", "placeholder"]

import { focusContentEditable } from '@/utils/document-utils';
import { debounce } from 'debounce';
import { markRaw, onMounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

type DebounceFunction = ((value: string) => void) & { clear(): void } & { flush(): void };


export default /*@__PURE__*/_defineComponent({
  ...{
    inheritAttrs: false,
},
  __name: 'VContentEditable',
  props: {
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    maxlength: { type: [Number, String], default: 0 },
    modelValue: { type: String, required: true, default: '' },
    placeholder: { type: String, default: '' },
    debounceMode: { type: Boolean, default: false },
    debounceInterval: { type: Number, default: 1000 },
    concurrencyMode: { type: Boolean, default: false },
},
  emits: ['edit-request', 'submit', 'update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {

// Other


const { t } = useI18n();
const emit = __emit;
const props = __props;

let value = ref('');
let element = ref(null as HTMLElement | null);
let focused = ref(false);
let debounceInstance = null as DebounceFunction | null;

const maxlengthNumber = computed(() => parseInt(props.maxlength as string, 10) || 0);

__expose({
    focus,
    value,
    element,
    focused,
    debounceInstance,
    maxlengthNumber,
});

function getElementValue(): string {
    return element?.value?.innerText.trim() ?? '';
}

function setElementValue(value: string) {
    if (!element.value) {
        return;
    }

    if (getElementValue() === value) {
        return;
    }

    element.value.innerText = value ?? '';
}

function onPaste(event: Event) {
    if (!props.editable) {
        emit('edit-request');
        setElementValue(value.value);
        return;
    }

    const clipboardEvent = event as ClipboardEvent & { originalEvent: ClipboardEvent };
    event.preventDefault();

    const plainText = (clipboardEvent.originalEvent || clipboardEvent).clipboardData?.getData('text/plain') ?? '';
    window.document.execCommand('insertText', false, plainText);
}

function onKeydown(event: KeyboardEvent) {
    if (event.shiftKey && event.code === 'Enter') {
        event.stopPropagation();
        return;
    }

    if (event.code === 'Enter') {
        event.preventDefault();
        emit('submit', value);
    }

    /* Any Shortcut except Ctrl + V */
    const isValidShortcut = event.ctrlKey && event.keyCode != 86;
    /* Backspace - Delete - Arrow Keys - Ctrl - Shift */
    const isValidKeyCode = [8, 16, 17, 37, 38, 39, 40, 46, 116].includes(event.keyCode);
    if (maxlengthNumber.value && value.value.length >= maxlengthNumber.value && !isValidKeyCode && !isValidShortcut) {
        event.preventDefault();
    }
}

function onBlur() {
    focused.value = false;
    debounceInstance?.flush();
    debounceInstance = null;
}

function onFocus() {
    focused.value = true;
}

function onInput() {
    if (!props.editable) {
        emit('edit-request');
        setElementValue(value.value);
        return;
    }

    let elementValue = getElementValue();

    // Fallback if the `maxlength` attribute does not work.
    if (maxlengthNumber.value && elementValue.length > maxlengthNumber.value) {
        value.value = elementValue.substring(0, maxlengthNumber.value);
        setElementValue(value.value);
        // After changing the value, you need to move the carriage at the end of the line
        focus();
        return;
    }

    value.value = elementValue;
    setElementValue(value.value);

    const emitUpdate = (newValue: string) => {
        emit('update:modelValue', newValue ?? '');
        debounceInstance = null;
    };

    if (props.debounceMode && !debounceInstance) {
        debounceInstance = markRaw(debounce(emitUpdate, props.debounceInterval));
    }

    if (props.debounceMode && debounceInstance) {
        debounceInstance(elementValue);
    } else {
        emitUpdate(elementValue);
    }
}

async function focus() {
    if (!element.value) {
        return;
    }

    setTimeout(focusContentEditable, 0, element.value);
}

watch(
    () => props.modelValue,
    (newValue: string) => {
        // If the user is in the input window, ignore all changes.
        if (props.concurrencyMode && focused.value) {
            return;
        }

        value.value = newValue;
        setElementValue(newValue);
    },
);

onMounted(() => {
    value.value = props.modelValue;
    setElementValue(props.modelValue);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _mergeProps(_ctx.$attrs, {
      ref_key: "element",
      ref: element,
      class: ["simple-contenteditable", { 'simple-contenteditable--disabled': __props.disabled }],
      contenteditable: !__props.disabled,
      disabled: "disabled",
      placeholder: _unref(value) && _unref(value) !== '' ? '' : __props.placeholder ? __props.placeholder : _unref(t)('default-placeholder', 1),
      onBlur: onBlur,
      onDrop: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
      onFocus: onFocus,
      onInput: onInput,
      onKeydown: onKeydown,
      onPaste: onPaste
    }), null, 16, _hoisted_2),
    (maxlengthNumber.value && _unref(value).length > maxlengthNumber.value * 0.8)
      ? _withDirectives((_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["simple-contenteditable-wrapper__legend", {
                'simple-contenteditable-wrapper__legend--negative': _unref(value).length >= maxlengthNumber.value,
            }])
        }, [
          _createElementVNode("span", null, _toDisplayString(_unref(value).length), 1),
          _cache[1] || (_cache[1] = _createTextVNode("/")),
          _createElementVNode("span", null, _toDisplayString(maxlengthNumber.value), 1)
        ], 2)), [
          [_vShow, _unref(focused)]
        ])
      : _createCommentVNode("", true)
  ]))
}
}

})