import DataTransferExtractor from '@/core/DataTransferExtractor';
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';

let isFileDrag = false;
function dragEnterHandler(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (isFileDrag || !event.dataTransfer || !DataTransferExtractor.containsFiles(event.dataTransfer)) {
        return;
    }

    if (isFileDrag === false) {
        isFileDrag = true;

        emitter.emit(EventNames.FileEnterWindow, event);
    }
}

function dragLeaveHandler(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const isDrop = event.type === 'drop';
    const isOutsideWindow = event.clientX + event.clientY === 0;

    if (isDrop || isOutsideWindow) {
        isFileDrag = false;

        emitter.emit(EventNames.FileLeaveWindow, event);
    }
}

document.addEventListener('dragover', dragEnterHandler, false);
document.addEventListener('dragenter', dragEnterHandler, false);

document.addEventListener('drop', dragLeaveHandler, false);
document.addEventListener('dragleave', dragLeaveHandler, false);
