import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-modal__row v-modal__row--header v-modal__title" }
const _hoisted_2 = { class: "v-modal__row v-modal__row--content v-modal__content" }
const _hoisted_3 = { class: "v-modal__row v-modal__row--actions" }

import VModal from './VModal.vue';
import VButton from './VButton.vue';

// Other
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import AlertContext, { AlertOpenEvent } from '@/core/Values/AlertContext';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VModalAlert',
  setup(__props) {

// Svg
// ...

// Components
const { t } = useI18n();

const visible = ref(false);
const context = ref(null as AlertContext | null);

emitter.on(EventNames.Alert, open);

function open(event: AlertOpenEvent) {
    visible.value = true;
    context.value = {
        title: t('alert-title'),
        buttonOkName: t('ok'),
        ...event,
    };
}

function ok() {
    if (context.value?.okCallback) {
        context.value.okCallback();
    }

    context.value = null;
    visible.value = false;
}

function close() {
    if (context.value?.cancelCallback) {
        context.value.cancelCallback();
    }

    context.value = null;
    visible.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VModal, {
    visible: visible.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((visible).value = $event)),
    class: "v-modal--error",
    onClose: close
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(context.value?.title), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(context.value?.message), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(VButton, {
          class: "button--rectangle button--center button--negative v-modal__action",
          onClick: ok
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(context.value?.buttonOkName), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})