import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "users-list" }
const _hoisted_2 = {
  key: 0,
  class: "users-list__item users-list__item--fluid"
}
const _hoisted_3 = { class: "users-list__control" }

import { useI18n } from 'vue-i18n';
import VButton from './VButton.vue';
import VUserCard from './VUserCard.vue';

// Other
import IUser from '@/core/Models/IUser';
import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VUsersList',
  props: {
    users: { type: Array as PropType<IUser[]>, default: () => [] },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
},
  emits: ['edit-request', 'open'],
  setup(__props, { emit: __emit }) {

// Svg

// Components
const { t } = useI18n();
const emit = __emit;
const props = __props;

function open() {
    if (props.disabled) {
        return;
    }

    if (!props.editable) {
        emit('edit-request');
        return;
    }

    emit('open');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", {
      ref: "list",
      class: _normalizeClass(["users-list__list", { 'users-list__list--multiply': __props.users.length > 1 }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.users, (user, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "users-list__item"
        }, [
          _createVNode(VUserCard, {
            "picture-only": __props.users.length > 1,
            user: user
          }, null, 8, ["picture-only", "user"])
        ]))
      }), 128)),
      (__props.users.length === 0)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
            _createVNode(VUserCard, {
              class: "button",
              empty: _unref(t)('default-placeholder', 2),
              onClick: open
            }, null, 8, ["empty"])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (!__props.disabled)
        ? (_openBlock(), _createBlock(VButton, {
            key: 0,
            class: "button--default button--secondary",
            onClick: open
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('users-control')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})